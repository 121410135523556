import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {
  Box,
  Grid,
  Card,
  Button,
  Typography,
  FormControl,
  InputAdornment,
  TextField,
  Chip,
  Paper,
  Icon,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';
import {ActionTab, NotFoundText, TransferListSkeleton} from 'shared/components';
import {
  VirtualizedTransferList,
  IVirtualizedTransferListItem,
} from 'shared/components/transfer-list/VirtualizedTransferList';
import {feedback} from 'shared/services';
import {
  IPeriodos,
  IPeriodosPost,
  PeriodosService,
} from 'shared/services/api/periodos/Periodos';
import {useUser} from 'shared/hooks';
import {Autocomplete} from '@mui/lab';
import {useLocation} from 'react-router-dom';

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  nextStep: () => void;
}

export const Periodos: React.FC<IStepProps> = ({
  idPublicoAlvo,
  previousStep,
  nextStep,
}) => {
  const {isCollege} = useUser();
  const [items, setItems] = useState<IPeriodos[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedItemsLeft, setCheckedItemsLeft] = useState<string[]>([]);
  const [checkedItemsRight, setCheckedItemsRight] = useState<string[]>([]);
  const [searchLeftSide, setSearchLeftSide] = useState('');
  const [searchRightSide, setSearchRightSide] = useState('');
  const [academicYears, setAcademicYears] = useState<string[]>([]);
  const location = useLocation();

  const getPeriodos = useCallback(
    (idPublicoAlvo: string, anoLetivo?: string[]) => {
      setIsLoading(true);
      PeriodosService.getById(idPublicoAlvo, anoLetivo)
        .then((response) => {
          if (response.Success) {
            setItems(response.Data.PeriodosAcademicos);
            setAcademicYears(anoLetivo as string[]);
          } else {
            feedback(response.Message || 'Erro ao carregar períodos.', 'error');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const years = useMemo(() => {
    const years: string[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 11; i++) {
      years.push(String(currentYear - i));
    }
    return years;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (isCollege) {
      PeriodosService.getById(idPublicoAlvo, [years[0]]).then((response) => {
        if (response.Success) {
          setAcademicYears(response.Data.AnosLetivos || []);
          if ((response.Data.AnosLetivos as string[]).length > 0) {
            setIsLoading(true);
            PeriodosService.getById(
              idPublicoAlvo,
              response.Data.AnosLetivos as string[],
            ).then((response) => {
              if (response.Success) {
                setItems((oldItems) => [
                  ...oldItems,
                  ...response.Data.PeriodosAcademicos.filter(
                    (responsePeriodo) =>
                      !oldItems.some(
                        (oldPeriodo) => responsePeriodo.Id === oldPeriodo.Id,
                      ),
                  ),
                ]);
              } else {
                feedback(
                  response.Message || 'Erro ao carregar períodos.',
                  'error',
                );
              }
              setIsLoading(false);
            });
          } else {
            setIsLoading(false);
          }
        } else {
          feedback(response.Message || 'Erro ao carregar períodos.', 'error');
        }
      });
    } else {
      PeriodosService.getById(idPublicoAlvo).then((response) => {
        if (response.Success) {
          setItems(response.Data.PeriodosAcademicos);
          setIsLoading(false);
        } else {
          feedback(response.Message || 'Erro ao carregar períodos.', 'error');
          setIsLoading(false);
        }
      });
    }
  }, [idPublicoAlvo, years, isCollege, location]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const leftItemsFilter = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    items
      .filter((item) => {
        return (
          !item.Selecionado &&
          normalizeText(item.DescricaoPeriodo).includes(
            normalizeText(searchLeftSide),
          )
        );
      })
      .forEach((item) => {
        result.push({
          id: item.Id,
          label: item.DescricaoPeriodo,
          isSelected: checkedItemsLeft.includes(item.Id),
        });
      });

    return result;
  }, [checkedItemsLeft, items, normalizeText, searchLeftSide]);

  const rightItemsFilter = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    items
      .filter((item) => {
        return (
          item.Selecionado &&
          normalizeText(item.DescricaoPeriodo).includes(
            normalizeText(searchRightSide),
          )
        );
      })
      .forEach((item) => {
        result.push({
          id: String(item.Id),
          label: item.DescricaoPeriodo,
          isSelected: checkedItemsRight.includes(item.Id),
        });
      });

    return result;
  }, [checkedItemsRight, items, normalizeText, searchRightSide]);

  const handleCheckLeft = useCallback((itemId: string) => {
    setCheckedItemsLeft((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const handleCheckRight = useCallback((itemId: string) => {
    setCheckedItemsRight((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const isAllCheckedLeft = useMemo(() => {
    return (
      leftItemsFilter.length > 0 &&
      leftItemsFilter.every((option) => option.isSelected)
    );
  }, [leftItemsFilter]);

  const isAllCheckedRight = useMemo(() => {
    return (
      rightItemsFilter.length > 0 &&
      rightItemsFilter.every((option) => option.isSelected)
    );
  }, [rightItemsFilter]);

  const handleCheckAllLeft = useCallback(() => {
    if (isAllCheckedLeft) {
      setCheckedItemsLeft([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoPeriodo).includes(
          normalizeText(searchLeftSide),
        ),
      )
      .map((item) => item.Id);
    setCheckedItemsLeft([...itemsId]);
  }, [isAllCheckedLeft, items, normalizeText, searchLeftSide]);

  const handleCheckAllRight = useCallback(() => {
    if (isAllCheckedRight) {
      setCheckedItemsRight([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoPeriodo).includes(
          normalizeText(searchLeftSide),
        ),
      )
      .map((item) => item.Id);
    setCheckedItemsRight([...itemsId]);
  }, [isAllCheckedRight, items, normalizeText, searchLeftSide]);

  const transferToRight = useCallback(() => {
    const optionsToTransfer: IPeriodos[] = items.map((item) => {
      const isSelected = checkedItemsLeft.includes(item.Id);

      return {
        ...item,
        Selecionado: isSelected ? true : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsLeft([]);
  }, [checkedItemsLeft, items]);

  const transferToLeft = useCallback(() => {
    const optionsToTransfer: IPeriodos[] = items.map((item) => {
      const isSelected = checkedItemsRight.includes(item.Id);

      return {
        ...item,
        Selecionado: isSelected ? false : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsRight([]);
  }, [checkedItemsRight, items]);

  const handleSaveAndNext = useCallback(() => {
    if (!rightItemsFilter.length) {
      feedback('Informe os períodos desejados.', 'error');
      return;
    }

    const periodosPost: IPeriodosPost = {
      IdPublicoAlvo: idPublicoAlvo,
      Filtros: [
        {
          Chave: 'periodos',
          IdsValoresSelecionados: rightItemsFilter.map((rItem) =>
            String(rItem.id),
          ),
        },
        {Chave: 'anosletivos', IdsValoresSelecionados: academicYears},
      ],
    };

    PeriodosService.post(periodosPost).then((data) => {
      if (data.Success) {
        nextStep();
      } else {
        feedback(data.Message, 'error');
      }
    });
  }, [idPublicoAlvo, nextStep, rightItemsFilter, academicYears]);

  const handleChangeAcademicYears = useCallback((value: string[]) => {
    setAcademicYears(value);
  }, []);

  const handleFilter = useCallback(() => {
    getPeriodos(idPublicoAlvo, academicYears);
  }, [getPeriodos, idPublicoAlvo, academicYears]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Períodos</strong>
            </Typography>
          </Box>
        </Grid>
        {isCollege && (
          <Grid
            container
            item
            xs={12}
            spacing={1}
            alignItems={'center'}
            style={{marginBottom: 16}}>
            <Grid item xs={12}>
              <Box width={'100%'}>
                <Typography variant="subtitle1">
                  <strong>Ano letivo</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box width={'100%'}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    multiple
                    id="ano-letivo"
                    options={years}
                    getOptionLabel={(option) => option}
                    value={academicYears}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, value) => {
                      handleChangeAcademicYears(value);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="primary"
                          label={option}
                          {...getTagProps({index})}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecione"
                        placeholder="Selecione"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Button
                startIcon={<FilterIcon />}
                fullWidth
                variant="outlined"
                size="medium"
                color="primary"
                onClick={handleFilter}
                disabled={false}>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por período"
                value={searchLeftSide}
                onChange={(e) => {
                  setSearchLeftSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por período"
                value={searchRightSide}
                onChange={(e) => {
                  setSearchRightSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={leftItemsFilter}
              isCheckedAll={isAllCheckedLeft}
              onCheckedAll={handleCheckAllLeft}
              onCheckedItem={handleCheckLeft}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Box
              width="100%"
              paddingX={2}
              display={'flex'}
              flexDirection={'column'}
              gap={1}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToRight}
                disabled={leftItemsFilter.length === 0 || isLoading}>
                <Icon>double_arrow_outlined</Icon>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToLeft}
                disabled={rightItemsFilter.length === 0 || isLoading}>
                <Icon style={{transform: 'rotate(180deg)'}}>
                  double_arrow_outlined
                </Icon>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={rightItemsFilter}
              isCheckedAll={isAllCheckedRight}
              onCheckedAll={handleCheckAllRight}
              onCheckedItem={handleCheckRight}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
      </Grid>
      <ActionTab
        onSaveLabel="Avançar"
        onCancelLabel="Voltar"
        onCancel={previousStep}
        onSave={isLoading ? () => false : handleSaveAndNext}
      />
    </>
  );
};
