import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    width: '56px',
    height: '56px',
    borderRadius: 0,
    left: theme.spacing(-0.5),
    color: `${theme.palette.background.paper} !important`,
    backgroundColor: 'transparent',
    position: 'relative',
    opacity: 1,
    zIndex: `${2} !important`,

    '&:hover': {
      opacity: 1,
      backgroundColor: 'transparent',
    },

    '&:after': {
      transform: 'translate(-50%, -50%)',
      content: "' '",
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '32px',
      height: '32px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderRadius: '50%',
      display: 'none',
      zIndex: `${-1} !important`,
    },
  },
  menuButtonActive: {
    opacity: 1,

    '&:after': {
      display: 'block',
    },
  },
  logo: {
    width: '125px',
    marginRight: '16px',
    marginLeft: '4px',
  },
  siteHeader: {
    boxShadow: 'none !important',
    zIndex: `${1201} !important`,
  },
}));
