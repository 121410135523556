import React, {useMemo, useState} from 'react';
import clsx from 'clsx';
import {
  Box,
  Hidden,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  ListItemText,
} from '@mui/material';
import {NavLink} from 'react-router-dom';

import {LogoGVdasa} from '../../assets';
import {useDrawer} from '../../hooks';

import {useStyles} from './styles';

import {UserService} from 'shared/services/api/user/User';
import {useFeedback} from 'shared/hooks/useFeedback';
import {ModalFeedBack} from 'pages/feedBack/components/ModalFeedBack';

export const MenuContent: React.FC = () => {
  const {closeMobileDrawer, menuOptions, hover} = useDrawer();
  const classes = useStyles();
  const [filtro, setFiltro] = useState('');
  const {openModalFeedBack, handleToggleModalFeedBack} = useFeedback();

  const filteredMenuOptions = useMemo(() => {
    if (menuOptions?.length) {
      return menuOptions.filter((item) =>
        item.name.toLowerCase().includes(filtro.toLowerCase()),
      );
    }
    return [];
  }, [filtro, menuOptions]);

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginTop={[0, '56px']}>
      <div>
        <Hidden smUp implementation="css">
          <img
            alt="Logo GVdasa"
            src={LogoGVdasa.default}
            className={classes.logo}
          />
        </Hidden>
        <List>
          <ListItem
            className={clsx(classes.inputBoxSearch, {
              [classes.inputBoxSearchOpen]: hover,
              [classes.inputBoxSearchClose]: !hover,
            })}
            classes={{
              root: clsx({
                [classes.inputBoxSearchOpen]: hover,
                [classes.inputBoxSearchClose]: !hover,
              }),
            }}>
            {hover ? (
              <TextField
                fullWidth
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                variant="outlined"
                className={classes.inputSearch}
                placeholder="Buscar"
                size="small"
                InputProps={{
                  classes: {
                    root: classes.inputSearch,
                    input: classes.input,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon
                        fontSize="medium"
                        className={classes.iconInputSearch}>
                        search
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <ListItemIcon className={classes.iconSearch}>
                <Icon fontSize="medium" className={classes.icon}>
                  search
                </Icon>
              </ListItemIcon>
            )}
          </ListItem>
          {menuOptions &&
            filteredMenuOptions.map((menuOption, index) => (
              <ListItem
                button
                key={index}
                component={NavLink as any}
                to={menuOption.path}
                onClick={closeMobileDrawer}
                style={
                  (({isActive}: any) =>
                    isActive
                      ? {
                          background: 'rgba(0, 0, 0, 0.12)',
                          borderLeft: '4px solid',
                          borderLeftColor: '#fff !important',
                        }
                      : {}) as any
                }
                className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                  <Icon fontSize="medium">{menuOption.icon}</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={menuOption.name}
                  style={{margin: '-2px 0 0'}}
                />
              </ListItem>
            ))}
        </List>
      </div>
      <div>
        <div className={classes.feedbackButtonItem}>
          <ListItem drawer-open={hover} onClick={handleToggleModalFeedBack}>
            <ListItemIcon>
              <Icon className={classes.iconFeedback} fontSize="medium">
                feedback
              </Icon>
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItem>
        </div>
        <div className={classes.quitButtonItem}>
          <ListItem button onClick={() => UserService.signout()}>
            <ListItemIcon>
              <Icon
                fontSize="medium"
                className={classes.iconLogout}
                style={{marginBottom: '0'}}>
                logout
              </Icon>
            </ListItemIcon>
            Sair
          </ListItem>
        </div>
      </div>
      <ModalFeedBack
        isOpen={openModalFeedBack}
        handleClose={handleToggleModalFeedBack}
      />
    </Box>
  );
};
