import React, {useEffect, useState} from 'react';

import {Fade, Grid, Theme, Typography, LinearProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {GVSplashLogo} from 'shared/assets';
import {useUser} from 'shared/hooks';

const useStyles = makeStyles((theme: Theme) => {
  return {
    logo: {
      position: 'relative',
      width: '80px',
      transition: 'transform .2s',
      '&:hover': {
        transform: 'scale(1.25)',
      },
    },
    barra: {
      marginTop: theme.spacing(6),
      width: theme.spacing(35),
      color: theme.palette.background.paper,
    },
    texto: {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(3),
    },
  };
});

export const SplashScreen: React.FC = ({children}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) return prevProgress;
        return prevProgress + 1;
      });
    }, 10);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setIsLoading(false);
    }
  }, [progress, setIsLoading]);

  if (isLoading)
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '100vh'}}>
        <Fade in>
          <Grid item>
            <img
              alt="Logo GVdasa"
              src={GVSplashLogo.default}
              className={classes.logo}
            />
          </Grid>
        </Fade>
        <Grid item>
          <Fade in>
            <LinearProgress
              color="primary"
              variant="determinate"
              value={progress}
              className={classes.barra}
            />
          </Fade>
        </Grid>
        <Fade in>
          <Grid item className={classes.texto}>
            <Typography variant="subtitle1">
              Carregando GVpúblico-alvo
            </Typography>
          </Grid>
        </Fade>
      </Grid>
    );

  return <>{children}</>;
};
