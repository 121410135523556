import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  FormControl,
  InputAdornment,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  Icon,
  Paper,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {ActionTab, NotFoundText, TransferListSkeleton} from 'shared/components';
import {
  VirtualizedTransferList,
  IVirtualizedTransferListItem,
} from 'shared/components/transfer-list/VirtualizedTransferList';
import {feedback} from 'shared/services';
import {
  ITurmas,
  ITurmasPost,
  SituacaoTurma,
  TurmasService,
} from 'shared/services/api/turmas/Turmas';
import {Autocomplete} from '@mui/lab';
import {useUser} from 'shared/hooks';
import {useNavigate, useLocation} from 'react-router-dom';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  nextStep: () => void;
}

export const Turmas: React.FC<IStepProps> = ({
  idPublicoAlvo,
  previousStep,
  nextStep,
}) => {
  const {isCollege} = useUser();
  const [items, setItems] = useState<ITurmas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedItemsLeft, setCheckedItemsLeft] = useState<string[]>([]);
  const [checkedItemsRight, setCheckedItemsRight] = useState<string[]>([]);
  const [searchLeftSide, setSearchLeftSide] = useState('');
  const [searchRightSide, setSearchRightSide] = useState('');
  const [statusOnClass, setStatusOnClass] = useState<SituacaoTurma[]>([]);
  const [statusOptions, setStatusOptions] = useState<SituacaoTurma[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    TurmasService.getById(idPublicoAlvo).then((response) => {
      if (response.Success) {
        setItems(response.Data);
        TurmasService.getSituacaoTurmaById(idPublicoAlvo).then((response) => {
          if (response.Success) {
            setStatusOnClass(
              response.Data.filter((status) => status.Selecionado),
            );
            setStatusOptions(response.Data);
            setIsLoading(false);
          } else {
            feedback(
              response.Message || 'Erro ao carregar situações de turma',
              'error',
            );
            setIsLoading(false);
          }
        });
      } else {
        feedback(response.Message || 'Erro ao carregar turmas.', 'error');
        setIsLoading(false);
      }
    });
  }, [idPublicoAlvo, location]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const leftItemsFilter = useMemo(() => {
    let result: IVirtualizedTransferListItem[] = [];
    let turmasFiltered: ITurmas[] = [];

    turmasFiltered = items.filter((item) => {
      return (
        !item.Selecionado &&
        normalizeText(item.DescricaoTurma).includes(
          normalizeText(searchLeftSide),
        )
      );
    });

    if (isCollege) {
      turmasFiltered = items.filter((item) => {
        return (
          !item.Selecionado &&
          normalizeText(item.DescricaoTurma).includes(
            normalizeText(searchLeftSide),
          )
        );
      });

      if (statusOnClass.length > 0) {
        turmasFiltered = turmasFiltered.filter((turma) => {
          return statusOnClass.some((status) => {
            return status.IdSituacaoTurma === (turma.IdSituacaoTurma as string);
          });
        });
      }
    }

    turmasFiltered.forEach((item) => {
      result.push({
        id: item.Id,
        label: item.DescricaoTurma,
        isSelected: checkedItemsLeft.includes(item.Id),
      });
    });

    return result;
  }, [
    checkedItemsLeft,
    items,
    normalizeText,
    searchLeftSide,
    isCollege,
    statusOnClass,
  ]);

  const rightItemsFilter = useMemo(() => {
    let result: IVirtualizedTransferListItem[] = [];
    let turmasFiltered: ITurmas[] = [];

    turmasFiltered = items.filter((item) => {
      return (
        item.Selecionado &&
        normalizeText(item.DescricaoTurma).includes(
          normalizeText(searchRightSide),
        )
      );
    });

    if (isCollege) {
      turmasFiltered = items.filter((item) => {
        return (
          item.Selecionado &&
          normalizeText(item.DescricaoTurma).includes(
            normalizeText(searchRightSide),
          )
        );
      });

      if (statusOnClass.length > 0) {
        turmasFiltered = turmasFiltered.filter((turma) => {
          return statusOnClass.some((status) => {
            return status.IdSituacaoTurma === (turma.IdSituacaoTurma as string);
          });
        });
      }
    }

    turmasFiltered.forEach((item) => {
      result.push({
        id: item.Id,
        label: item.DescricaoTurma,
        isSelected: checkedItemsRight.includes(item.Id),
      });
    });

    return result;
  }, [
    checkedItemsRight,
    items,
    normalizeText,
    searchRightSide,
    isCollege,
    statusOnClass,
  ]);

  const handleCheckLeft = useCallback((itemId: string) => {
    setCheckedItemsLeft((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const handleCheckRight = useCallback((itemId: string) => {
    setCheckedItemsRight((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const isAllCheckedLeft = useMemo(() => {
    return (
      leftItemsFilter.length > 0 &&
      leftItemsFilter.every((option) => option.isSelected)
    );
  }, [leftItemsFilter]);

  const isAllCheckedRight = useMemo(() => {
    return (
      rightItemsFilter.length > 0 &&
      rightItemsFilter.every((option) => option.isSelected)
    );
  }, [rightItemsFilter]);

  const handleCheckAllLeft = useCallback(() => {
    if (isAllCheckedLeft) {
      setCheckedItemsLeft([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoTurma).includes(
          normalizeText(searchLeftSide),
        ),
      )
      .map((item) => item.Id);
    setCheckedItemsLeft([...itemsId]);
  }, [isAllCheckedLeft, items, searchLeftSide, normalizeText]);

  const handleCheckAllRight = useCallback(() => {
    if (isAllCheckedRight) {
      setCheckedItemsRight([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoTurma).includes(
          normalizeText(searchRightSide),
        ),
      )
      .map((item) => item.Id);
    setCheckedItemsRight([...itemsId]);
  }, [isAllCheckedRight, items, searchRightSide, normalizeText]);

  const transferToRight = useCallback(() => {
    const optionsToTransfer: ITurmas[] = items.map((item) => {
      const isSelected = checkedItemsLeft.includes(item.Id);

      return {
        ...item,
        Selecionado: isSelected ? true : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsLeft([]);
  }, [checkedItemsLeft, items]);

  const transferToLeft = useCallback(() => {
    const optionsToTransfer: ITurmas[] = items.map((item) => {
      const isSelected = checkedItemsRight.includes(item.Id);

      return {
        ...item,
        Selecionado: isSelected ? false : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsRight([]);
  }, [checkedItemsRight, items]);

  const handleSaveAndNext = useCallback(() => {
    setIsLoading(true);
    if (!rightItemsFilter.length) {
      setIsLoading(false);
      feedback('Informe as turmas desejadas!', 'error');
      return;
    }

    const situacoesPost: ITurmasPost = {
      Chave: 'situacaoturma',
      IdPublicoAlvo: idPublicoAlvo,
      IdsValoresSelecionados: statusOnClass.map(
        (status) => status.IdSituacaoTurma,
      ),
    };

    const turmasPost: ITurmasPost = {
      Chave: 'turmas',
      IdPublicoAlvo: idPublicoAlvo,
      IdsValoresSelecionados: rightItemsFilter.map((rItem) => rItem.id),
    };

    TurmasService.post(turmasPost).then((data) => {
      if (data.Success) {
        TurmasService.postSituacoesTurma(situacoesPost).then((response) => {
          if (response.Success) {
            if (!isCollege) {
              setOpenDialog(true);
            } else {
              nextStep();
            }
          } else {
            feedback(
              data.Message || 'Erro ao salvar situações de turma.',
              'error',
            );
          }
        });
      } else {
        feedback(data.Message || 'Erro ao salvar turmas.', 'error');
      }
    });
    setIsLoading(false);
  }, [idPublicoAlvo, nextStep, rightItemsFilter, statusOnClass, isCollege]);

  const handleStartBackgroundProcess = useCallback(
    (idPublicoAlvo: string) => {
      setIsLoading(true);
      PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then(() => {
        setIsLoading(false);
        navigate('/publico-alvo');
      });
    },
    [navigate],
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Turmas</strong>
            </Typography>
          </Box>
        </Grid>
        {isCollege === true && (
          <Grid item xs={12}>
            <Box width={'100%'}>
              <Box width={'100%'}>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Situação da turma</strong>
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    disableListWrap
                    fullWidth
                    multiple
                    loading={isLoading}
                    id="situacao-turma"
                    options={statusOptions}
                    getOptionLabel={(option) => option.SituacaoTurma}
                    value={statusOnClass}
                    isOptionEqualToValue={(option, value) =>
                      option.IdSituacaoTurma === value.IdSituacaoTurma
                    }
                    onChange={(event, value) => {
                      setStatusOnClass(value);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="primary"
                          label={option.SituacaoTurma}
                          {...getTagProps({index})}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecione"
                        placeholder="Selecione"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por turmas"
                value={searchLeftSide}
                onChange={(e) => {
                  setSearchLeftSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por turmas"
                value={searchRightSide}
                onChange={(e) => {
                  setSearchRightSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={leftItemsFilter}
              isCheckedAll={isAllCheckedLeft}
              onCheckedAll={handleCheckAllLeft}
              onCheckedItem={handleCheckLeft}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Box
              width="100%"
              paddingX={2}
              display={'flex'}
              flexDirection={'column'}
              gap={1}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToRight}
                disabled={leftItemsFilter.length === 0 || isLoading}>
                <Icon>double_arrow_outlined</Icon>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToLeft}
                disabled={rightItemsFilter.length === 0 || isLoading}>
                <Icon style={{transform: 'rotate(180deg)'}}>
                  double_arrow_outlined
                </Icon>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={rightItemsFilter}
              isCheckedAll={isAllCheckedRight}
              onCheckedAll={handleCheckAllRight}
              onCheckedItem={handleCheckRight}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        aria-labelledby="modal-detail">
        <DialogTitle id="modal-detail-title">
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyItems={'space-between'}>
            <Typography color="primary" variant="h6">
              Turmas salvas com sucesso!
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1">
            Estamos realizando a busca das pessoas. Agora, você será
            redirecionado para a página inicial do Público-alvo. Enviaremos uma
            notificação ao concluir esse processo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={() => {
              handleStartBackgroundProcess(idPublicoAlvo);
            }}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ActionTab
        onSaveLabel="Avançar"
        onCancelLabel="Voltar"
        onCancel={previousStep}
        onSave={isLoading ? () => false : handleSaveAndNext}
      />
    </>
  );
};
