import React, {memo, useMemo} from 'react';
import {
  Box,
  Card,
  Divider,
  Checkbox,
  IconButton,
  CardHeader,
  Typography,
  Theme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {areEqual, FixedSizeList, ListChildComponentProps} from 'react-window';

const useStyles = makeStyles((theme: Theme) => ({
  labelItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export interface IVirtualizedTransferListItem {
  id: string;
  label: string;
  isSelected: boolean;
  isExpanded?: boolean;
  hasChildren?: boolean;
  nivel?: string;
}
interface IVirtualizedTransferListProps {
  title: string;
  items: IVirtualizedTransferListItem[];
  isCheckedAll: boolean;
  onCheckedAll: () => void;
  onCheckedItem: (itemId: string, nivel: string) => void;
  onExpandedItem?: (itemId: string) => void;
}

export const VirtualizedTransferList: React.FC<IVirtualizedTransferListProps> =
  memo(
    ({
      title,
      items,
      isCheckedAll,
      onCheckedAll,
      onCheckedItem,
      onExpandedItem,
    }) => {
      return (
        <Card style={{width: '100%'}}>
          <CardHeader
            style={{padding: '0 8px', backgroundColor: '#EAEAEA'}}
            avatar={
              <Box key={title} role="listitem">
                <Checkbox checked={isCheckedAll} onChange={onCheckedAll} />
              </Box>
            }
            title={<Typography variant="body1">{title}</Typography>}
          />
          <Divider />
          <FixedSizeList
            height={400}
            width="100%"
            itemSize={40}
            itemData={{items, onCheckedItem, onExpandedItem}}
            itemCount={items.length}>
            {RenderRow}
          </FixedSizeList>
        </Card>
      );
    },
  );

const RenderRow = memo((props: ListChildComponentProps) => {
  const {index, style, data} = props;
  const currentItem: IVirtualizedTransferListItem = useMemo(
    () => data.items[index],
    [data, index],
  );

  const PADDING_SIZE = 8;

  const classes = useStyles();

  return (
    <div
      style={{
        ...style,
        top: `${parseFloat(String(style.top)) + PADDING_SIZE}px`,
      }}
      key={index}>
      <Box
        display="flex"
        alignItems="center"
        justifyItems="space-between"
        paddingX={0}
        paddingLeft={currentItem.nivel ? Number(currentItem.nivel) * 1 : 1}>
        <Box display="flex" alignItems="center" gap={1} overflow="hidden">
          <Checkbox
            checked={currentItem.isSelected}
            onChange={() =>
              data.onCheckedItem(currentItem.id, currentItem.nivel)
            }
          />
          <Typography
            title={currentItem.label}
            variant="body2"
            className={classes.labelItem}>
            {currentItem.label}
          </Typography>
        </Box>
        <Box>
          {currentItem.hasChildren && (
            <IconButton
              size="small"
              onClick={() => data.onExpandedItem(currentItem.id)}>
              {currentItem.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Box>
      </Box>
    </div>
  );
}, areEqual);
