import React from 'react';

import {Icon, IconButton} from '@mui/material';

import {Tooltip} from '../tooltip/Tooltip';

interface IActionButtonsProps {
  rowId: string | number;
  editTooltipText?: string;
  deleteTooltipText?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  hasTooltip?: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
  hideDeleteButton?: boolean;
}

export const ActionButtons: React.FC<IActionButtonsProps> = ({
  rowId,
  editTooltipText,
  deleteTooltipText,
  canEdit,
  canDelete,
  hasTooltip,
  onClickEdit,
  onClickDelete,
  hideDeleteButton = false,
}) => {
  const deleteMessage = deleteTooltipText
    ? deleteTooltipText
    : 'Não é permitido excluir o registro';
  const editMessage = editTooltipText
    ? editTooltipText
    : 'Não é permitido editar o registro';

  return (
    <>
      {hasTooltip ? (
        <>
          <Tooltip text={canEdit ? 'Editar' : editMessage}>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={onClickEdit}
              disabled={!canEdit}>
              <Icon fontSize={'small'}>edit</Icon>
            </IconButton>
          </Tooltip>
          {!hideDeleteButton && (
            <Tooltip text={canDelete ? 'Excluir' : deleteMessage}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={onClickDelete}
                disabled={!canDelete}>
                <Icon fontSize={'small'}>delete</Icon>
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={onClickEdit}
            disabled={!canEdit}>
            <Icon fontSize={'small'}>edit</Icon>
          </IconButton>
          {!hideDeleteButton && (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={onClickDelete}
              disabled={!canDelete}>
              <Icon fontSize={'small'}>delete</Icon>
            </IconButton>
          )}
        </>
      )}
    </>
  );
};
