import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  FormControl,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  Paper,
  CircularProgress,
  TextField,
  Icon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {ActionTab, NotFoundText, TransferListSkeleton} from 'shared/components';
import {
  VirtualizedTransferList,
  IVirtualizedTransferListItem,
} from 'shared/components/transfer-list/VirtualizedTransferList';
import {feedback} from 'shared/services';
import {
  IDisciplina,
  IDisciplinasPost,
  DisciplinasService,
} from 'shared/services/api/disciplinas/Disciplinas';
import {useNavigate, useLocation} from 'react-router-dom';
import {useUser} from 'shared/hooks';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  nextStep: () => void;
}

export const Disciplinas: React.FC<IStepProps> = ({
  idPublicoAlvo,
  previousStep,
  nextStep,
}) => {
  const [items, setItems] = useState<IDisciplina[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedItemsLeft, setCheckedItemsLeft] = useState<string[]>([]);
  const [checkedItemsRight, setCheckedItemsRight] = useState<string[]>([]);
  const [searchLeftSide, setSearchLeftSide] = useState('');
  const [searchRightSide, setSearchRightSide] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const {isCollege} = useUser();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    DisciplinasService.getById(idPublicoAlvo).then((response) => {
      if (response.Success) {
        setItems(response.Data);
        setIsLoading(false);
      } else {
        feedback(response.Message || 'Erro ao carregar disciplinas.', 'error');
      }
    });
  }, [idPublicoAlvo, location]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const leftItemsFilter = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    items
      .filter((item) => {
        return (
          !item.Selecionado &&
          normalizeText(item.DescricaoDisciplina).includes(
            normalizeText(searchLeftSide),
          )
        );
      })
      .forEach((item) => {
        result.push({
          id: item.CodigoDisciplina,
          label: item.DescricaoDisciplina,
          isSelected: checkedItemsLeft.includes(item.CodigoDisciplina),
        });
      });

    return result;
  }, [checkedItemsLeft, items, normalizeText, searchLeftSide]);

  const rightItemsFilter = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    items
      .filter((item) => {
        return (
          item.Selecionado &&
          normalizeText(item.DescricaoDisciplina).includes(
            normalizeText(searchRightSide),
          )
        );
      })
      .forEach((item) => {
        result.push({
          id: item.CodigoDisciplina,
          label: item.DescricaoDisciplina,
          isSelected: checkedItemsRight.includes(item.CodigoDisciplina),
        });
      });

    return result;
  }, [checkedItemsRight, items, normalizeText, searchRightSide]);

  const handleCheckLeft = useCallback((itemId: string) => {
    setCheckedItemsLeft((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const handleCheckRight = useCallback((itemId: string) => {
    setCheckedItemsRight((old) => {
      if (old.includes(itemId)) {
        return [...old.filter((oldItemId) => oldItemId !== itemId)];
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const isAllCheckedLeft = useMemo(() => {
    return (
      leftItemsFilter.length > 0 &&
      leftItemsFilter.every((option) => option.isSelected)
    );
  }, [leftItemsFilter]);

  const isAllCheckedRight = useMemo(() => {
    return (
      rightItemsFilter.length > 0 &&
      rightItemsFilter.every((option) => option.isSelected)
    );
  }, [rightItemsFilter]);

  const handleCheckAllLeft = useCallback(() => {
    if (isAllCheckedLeft) {
      setCheckedItemsLeft([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoDisciplina).includes(
          normalizeText(searchLeftSide),
        ),
      )
      .map((item) => item.CodigoDisciplina);
    setCheckedItemsLeft([...itemsId]);
  }, [isAllCheckedLeft, items, searchLeftSide, normalizeText]);

  const handleCheckAllRight = useCallback(() => {
    if (isAllCheckedRight) {
      setCheckedItemsRight([]);
      return;
    }

    const itemsId = items
      .filter((item) =>
        normalizeText(item.DescricaoDisciplina).includes(
          normalizeText(searchRightSide),
        ),
      )
      .map((item) => item.CodigoDisciplina);
    setCheckedItemsRight([...itemsId]);
  }, [isAllCheckedRight, items, searchRightSide, normalizeText]);

  const transferToRight = useCallback(() => {
    const optionsToTransfer: IDisciplina[] = items.map((item) => {
      const isSelected = checkedItemsLeft.includes(item.CodigoDisciplina);

      return {
        ...item,
        Selecionado: isSelected ? true : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsLeft([]);
  }, [checkedItemsLeft, items]);

  const transferToLeft = useCallback(() => {
    const optionsToTransfer: IDisciplina[] = items.map((item) => {
      const isSelected = checkedItemsRight.includes(item.CodigoDisciplina);

      return {
        ...item,
        Selecionado: isSelected ? false : item.Selecionado,
      };
    });

    setItems([...optionsToTransfer]);
    setCheckedItemsRight([]);
  }, [checkedItemsRight, items]);

  const handleSaveAndNext = useCallback(() => {
    if (!rightItemsFilter.length) {
      feedback('Informe as disciplinas desejadas.', 'error');
      return;
    }

    const disciplinasPost: IDisciplinasPost = {
      Chave: 'disciplinas',
      IdPublicoAlvo: idPublicoAlvo,
      IdsValoresSelecionados: rightItemsFilter.map((rItem) => rItem.id),
    };

    DisciplinasService.post(disciplinasPost).then((data) => {
      if (data.Success) {
        if (isCollege) {
          setOpenDialog(true);
        } else {
          nextStep();
        }
      } else {
        feedback(data.Message, 'error');
      }
    });
  }, [idPublicoAlvo, nextStep, rightItemsFilter, isCollege]);

  const handleStartBackgroundProcess = useCallback(
    (idPublicoAlvo: string) => {
      setIsLoading(true);
      PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then(() => {
        setIsLoading(false);
        navigate('/publico-alvo');
      });
    },
    [navigate],
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Disciplinas</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por disciplinas"
                value={searchLeftSide}
                onChange={(e) => {
                  setSearchLeftSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por disciplinas"
                value={searchRightSide}
                onChange={(e) => {
                  setSearchRightSide(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={leftItemsFilter}
              isCheckedAll={isAllCheckedLeft}
              onCheckedAll={handleCheckAllLeft}
              onCheckedItem={handleCheckLeft}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Box
              width="100%"
              paddingX={2}
              display={'flex'}
              flexDirection={'column'}
              gap={1}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToRight}
                disabled={leftItemsFilter.length === 0 || isLoading}>
                <Icon>double_arrow_outlined</Icon>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={transferToLeft}
                disabled={rightItemsFilter.length === 0 || isLoading}>
                <Icon style={{transform: 'rotate(180deg)'}}>
                  double_arrow_outlined
                </Icon>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {isLoading ? (
            <TransferListSkeleton />
          ) : items.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={rightItemsFilter}
              isCheckedAll={isAllCheckedRight}
              onCheckedAll={handleCheckAllRight}
              onCheckedItem={handleCheckRight}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        aria-labelledby="modal-detail">
        <DialogTitle id="modal-detail-title">
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyItems={'space-between'}>
            <Typography color="primary" variant="h6">
              Disciplinas salvas com sucesso!
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1">
            Estamos realizando a busca das pessoas. Agora, você será
            redirecionado para a página inicial do Público-alvo. Enviaremos uma
            notificação ao concluir esse processo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={() => {
              handleStartBackgroundProcess(idPublicoAlvo);
            }}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ActionTab
        onSaveLabel="Avançar"
        onCancelLabel="Voltar"
        onCancel={previousStep}
        onSave={isLoading ? () => false : handleSaveAndNext}
      />
    </>
  );
};
