import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  Box,
  Typography,
  Icon,
  Theme,
  Tooltip,
  TextField,
  CircularProgress,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import {Pagination, ToggleButton, ToggleButtonGroup} from '@mui/lab';
import {
  IUsuarios,
  UsuariosService,
} from 'shared/services/api/usuarios/Usuarios';
import {feedback} from 'shared/services';
import {Environment} from 'shared/Env';
import {PermissionsListSkeleton} from './components/PermissionsListSkeleton';
import {useTitle} from 'shared/hooks';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const Usuarios: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {setTitle} = useTitle();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [usuarios, setUsuarios] = useState<IUsuarios[]>([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);
  const [searchUserPermissions, setSearchUserPermissions] = useState<
    IUsuarios[]
  >([]);
  const [toggleButtonValue, setToggleButtonValue] = useState(0);

  useEffect(() => {
    setTitle('Usuários');
  }, [setTitle]);

  useEffect(() => {
    UsuariosService.getUsuarios().then((data) => {
      if (data.Success) {
        setUsuarios(data.Data);
        setSearchUserPermissions(data.Data);
      } else {
        feedback(data.Message || 'Erro ao listar os usuários.', 'error');
      }
      setIsLoading(false);
    });
  }, []);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const allUserPermissions = useMemo(() => {
    const result = searchUserPermissions;
    return result;
  }, [searchUserPermissions]);

  const enabledUserPermissions = useMemo(() => {
    const result = searchUserPermissions.filter((item) => item.Ativo === true);
    return result;
  }, [searchUserPermissions]);

  const disabledUserPermissions = useMemo(() => {
    const result = searchUserPermissions.filter((item) => item.Ativo === false);
    return result;
  }, [searchUserPermissions]);

  const handleButtonsFiltering = useCallback(
    (buttonValue: number) => {
      switch (buttonValue) {
        case 1:
          setUsuarios([...enabledUserPermissions]);
          break;
        case 2:
          setUsuarios([...disabledUserPermissions]);
          break;

        default:
          setUsuarios([...allUserPermissions]);
          break;
      }
      setPage(1);
    },
    [allUserPermissions, disabledUserPermissions, enabledUserPermissions],
  );

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    value: number,
  ) => {
    setToggleButtonValue(value);
    handleButtonsFiltering(value);
  };

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      const valor = removeAccentuation(e.target.value);

      const result = searchUserPermissions.filter(
        (item) =>
          removeAccentuation(item.Nome).indexOf(valor) > -1 ||
          removeAccentuation(item.Login).indexOf(valor) > -1,
      );

      if (e.target.value === '') {
        setUsuarios(result);
      } else {
        setUsuarios(result);
      }

      setPage(1);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchUserPermissions],
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0} alignItems={'center'}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquise pelo nome do perfil"
                  value={search}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        {isLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <SearchIcon color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <ToggleButtonGroup
              size="small"
              value={toggleButtonValue}
              exclusive
              onChange={handleToggleButtonChange}>
              <ToggleButton
                disabled={allUserPermissions.length > 0 ? false : true}
                color="primary"
                value={0}
                className={classes.toggleButton}>
                Todos
              </ToggleButton>
              <ToggleButton
                disabled={enabledUserPermissions.length > 0 ? false : true}
                color="primary"
                value={1}
                className={classes.toggleButton}>
                Somente habilitados
              </ToggleButton>
              <ToggleButton
                disabled={disabledUserPermissions.length > 0 ? false : true}
                color="primary"
                value={2}
                className={classes.toggleButton}>
                Não habilitados
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {isLoading ? (
            <>
              <PermissionsListSkeleton />
            </>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Login
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Nome
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      width={100}
                      align="right">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usuarios.length ? (
                    usuarios
                      ?.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                      )
                      .map((usuarios) => (
                        <TableRow hover key={usuarios.CodigoUsuario}>
                          <TableCell component="th" scope="row">
                            <Box display={'flex'} gap={1} alignItems={'center'}>
                              <Icon
                                color={
                                  usuarios.Ativo ? 'secondary' : 'disabled'
                                }
                                fontSize={'small'}>
                                {usuarios.Ativo ? 'check_circle' : 'circle'}
                              </Icon>
                              <Typography>{usuarios.Login}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {usuarios.Nome}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Tooltip
                              title={
                                usuarios.Ativo
                                  ? 'Gerenciar permissões'
                                  : 'Adicionar permissões'
                              }>
                              <IconButton
                                disabled={false}
                                size={'small'}
                                onClick={() => {
                                  if (usuarios.IdUsuario !== null) {
                                    navigate(
                                      `/detalhes-permissoes/${usuarios.CodigoUsuario}/${usuarios.Nome}`,
                                    );
                                  } else {
                                    localStorage.setItem(
                                      'currentCodigoUsuario',
                                      String(usuarios.CodigoUsuario),
                                    );
                                    // setCurrentCodigoUsuario(
                                    //   String(usuarios.CodigoUsuario),
                                    // );
                                    setTimeout(() => {
                                      navigate(
                                        `/adicionar-permissoes/${usuarios.CodigoUsuario}/${usuarios.Nome}`,
                                      );
                                    }, 100);
                                  }
                                }}>
                                <Icon fontSize={'small'}>
                                  {usuarios.Ativo ? 'edit' : 'person_add'}
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Box padding={2}>
                          <Typography color="secondary">
                            Nenhum registro encontrado...
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              {rowsPerPage && usuarios.length > 0 && (
                <Box
                  marginTop={1}
                  marginBottom={1}
                  marginLeft={1}
                  marginRight={1}
                  display={'flex'}
                  justifyItems={'end'}>
                  <Pagination
                    page={page}
                    count={Math.ceil(usuarios.length / rowsPerPage)}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
