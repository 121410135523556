import React, {useCallback} from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import {Menu} from '@mui/icons-material';
import {LogoGVdasa} from '../../assets';
import {useDrawer, useTitle} from '../../hooks';
import {useStyles} from './styles';
import {UserAvatar, Notification} from 'shared/components';
import {ModalFeedBack} from 'pages/feedBack/components/ModalFeedBack';

export const Header: React.FC = () => {
  const {toggleMobileDrawer, hover, setHover, clicked, setClicked} =
    useDrawer();
  const {title} = useTitle();

  const classes = useStyles();

  const handleDrawerOpen = useCallback(() => {
    setHover(!hover);
    setClicked(!hover);
  }, [setHover, hover, setClicked]);

  return (
    <AppBar position="fixed" color="primary" className={classes.siteHeader}>
      <Toolbar style={{paddingLeft: 16, paddingRight: 16}}>
        <Hidden smUp implementation="css">
          <IconButton
            edge="start"
            aria-label="Abre menu"
            onClick={toggleMobileDrawer}
            className={classes.menuButton}>
            <Menu />
          </IconButton>
        </Hidden>

        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyItems="space-between">
          <Grid item style={{display: 'flex'}} xs={8} sm={7} md={5} lg={4}>
            <Box
              alignSelf="center"
              display="flex"
              alignItems="center"
              justifyItems="flex-start">
              <Hidden xsDown implementation="css">
                <IconButton
                  edge="start"
                  aria-label="Abre menu"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButton, {
                    [classes.menuButtonActive]: clicked,
                    [classes.menuButton]: !clicked,
                  })}>
                  <Menu />
                </IconButton>
              </Hidden>
              <img
                alt="Logo GVdasa"
                src={LogoGVdasa.default}
                className={classes.logo}
              />
              <Typography
                color="inherit"
                variant="h6"
                noWrap
                style={{
                  textOverflow: 'ellipsis',
                  width: 'auto',
                }}>
                {title}
              </Typography>
            </Box>
          </Grid>

          <Grid
            container
            item
            justifyContent="flex-end"
            xs={4}
            sm={5}
            md={7}
            lg={8}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyItems={'space-between'}
              gap={4}>
              <Notification />
              <UserAvatar />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
