import {Api} from 'shared/services/axios-config';

export interface IConfiguracaoRecorrencia {
  Id?: string;
  ConfigRecorrenciaId?: string;
  QtdDiasTentativa: number;
}

export interface IPostConfiguracaoRecorrencia {
  id: string;
}

const getConfiguracaoRecorrencia = async (): Promise<
  IConfiguracaoRecorrencia | undefined
> => {
  try {
    const {data} = await Api.get('/configuracaorecorrencia');

    if (data) {
      return data;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const createConfiguracaoRecorrencia = async (
  payload: Omit<IConfiguracaoRecorrencia, 'ConfigRecorrenciaId'>,
): Promise<IPostConfiguracaoRecorrencia | undefined> => {
  try {
    const {data} = await Api.post('/configuracaorecorrencia', payload);

    if (data) {
      return data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const editConfiguracaoRecorrencia = async (
  payload: IConfiguracaoRecorrencia,
): Promise<IPostConfiguracaoRecorrencia | undefined> => {
  try {
    const {data} = await Api.put('/configuracaorecorrencia', payload);

    if (data) {
      return data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const RecorrenciaService = {
  getConfiguracaoRecorrencia,
  createConfiguracaoRecorrencia,
  editConfiguracaoRecorrencia,
};
