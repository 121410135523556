import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  actionTabButtonsParent: {
    width: '100%',
    height: '78px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
    padding: '0 48px',

    '& button': {
      width: '170px',
    },
  },
}));
