import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IDisciplina {
  CodigoDisciplina: string;
  DescricaoDisciplina: string;
  Selecionado: boolean;
}

export interface IDisciplinasPost {
  Chave: string;
  IdsValoresSelecionados: string[];
  IdPublicoAlvo: string;
}

const getById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<IDisciplina[]>> => {
  try {
    const {data} = await Api.get(`Disciplinas/${idPublicoAlvo}`);

    const response: IResultOfAction<IDisciplina[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  disciplinas: IDisciplinasPost,
): Promise<IResultOfAction<IDisciplinasPost>> => {
  try {
    const {data} = await Api.post(`Disciplinas`, disciplinas);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const DisciplinasService = {
  getById,
  post,
};
