import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Divider,
  Typography,
  Avatar,
  useTheme,
} from '@mui/material';
import {UserService} from 'shared/services/api/user/User';
import {NavLink} from 'react-router-dom';
import {useUser} from 'shared/hooks';

export const AvatarPopover: React.FC = () => {
  const {user} = useUser();
  const theme = useTheme();

  return (
    <Box width={'250px'}>
      <Box
        p={2}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}>
        <Avatar
          style={{
            background: theme.palette.secondary.main,
            cursor: 'pointer',
            width: 50,
            height: 50,
            textAlign: 'center',
          }}>
          {user.name ? (
            <Typography variant={'h5'}>
              {user.name[0].toUpperCase() || ''}
            </Typography>
          ) : (
            <Icon>person</Icon>
          )}
        </Avatar>
        <Box padding={1} textAlign={'center'} width={'100%'}>
          <Typography
            style={{
              width: '200px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {user.name || ''}
          </Typography>
        </Box>
      </Box>
      <Box>
        <List>
          <ListItem
            to={{
              pathname: `//${user.forgotPasswordLink.replace(
                /(^\w+:|^)\/\//,
                '',
              )}`,
            }}
            key={'change-password-link'}
            button
            component={NavLink}
            target={'_blank'}>
            <ListItemIcon>
              <Icon fontSize="small" color={'disabled'}>
                vpn_key
              </Icon>
            </ListItemIcon>
            <ListItemText primary="Alterar senha" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => UserService.signout()}>
            <ListItemIcon>
              <Icon fontSize="small" color={'disabled'}>
                logout
              </Icon>
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
