import {Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      background: 'rgba(0, 0, 0, 0.12)',
      borderLeft: '4px solid',
      borderLeftColor: '#fff !important',
    },
    menuItem: {
      height: '54px',
      fontSize: '1rem',
      fontWeight: 400,
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      '&:hover': {
        backgroundColor: '#01B3AA !important',
      },
    },
    icon: {
      color: `${theme.palette.background.paper} !important`,
      margin: '0 0 3px 7.2px',
      minWidth: '49px',
    },
    menuSection: {
      height: '64px',
    },
    divider: {
      background: theme.palette.background.paper,
    },
    logo: {
      padding: theme.spacing(1),
      userSelect: 'none',
      width: '70%',
    },
    feedbackButtonItem: {
      fontSize: '1rem',
      fontWeight: 400,
      paddingTop: '10px',
      paddingBottom: '8px',
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      height: '56px',
      margintop: '10 !important',
      overflow: 'hidden',

      '&:hover': {
        backgroundColor: '#01B3AA !important',
      },

      '& > div': {
        height: '100%',
      },
    },
    quitButtonItem: {
      fontSize: '1rem',
      fontWeight: 400,
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      height: '56px',
      marginLeft: '0 !important',
      overflow: 'hidden',

      '&:hover': {
        backgroundColor: '#01B3AA !important',
      },

      '& > div': {
        height: '100%',
      },
    },
    inputBoxSearch: {
      height: theme.spacing(11.25),
      color: `${theme.palette.background.paper} !important`,
      position: 'relative',
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 2,
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      top: 0,
    },
    inputBoxSearchOpen: {
      width: '280px',
      zIndex: theme.zIndex.drawer + 2,
      transition: 'width 0.3s ease 0.05s',
    },
    inputBoxSearchClose: {
      zIndex: theme.zIndex.drawer + 2,
      transition: 'width 0.3s ease',
      overflowX: 'hidden',
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    inputSearch: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 8,
    },
    input: {
      color: `${theme.palette.background.paper} !important`,
      '&::placeholder': {
        color: '#FFF',
        opacity: 1,
      },
    },
    iconInputSearch: {
      color: '#FFF',
      marginLeft: -6.5,
    },
    iconSearch: {
      marginTop: 7,
      marginLeft: -8.5,
    },

    iconFeedback: {
      marginLeft: -5.5,
      marginTop: 2,
      color: `${theme.palette.background.paper} !important`,
      margin: '0 0 3px 7.2px',
      minWidth: '49px',
    },
    iconLogout: {
      marginLeft: -5.5,
      color: `${theme.palette.background.paper} !important`,
      margin: '0 0 3px 7.2px',
      minWidth: '49px',
    },
  }),
);
