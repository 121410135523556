import React from 'react';
import {
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Paper,
} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const PermissionsListSkeleton: React.FC = () => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="rectangular" width="auto" height={25} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
