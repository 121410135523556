import React, {useEffect, useState} from 'react';
import {Box, Typography, Checkbox} from '@mui/material';
import {ExpandMore, ChevronRight} from '@mui/icons-material';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {IEstablishmentTree} from 'shared/services/api/estabelecimentos/Estabelecimentos';

interface IEstabTreeSelectionProps {
  establishments: IEstablishmentTree[];
  onChangeEstabs: (establishments: string[]) => void;
  selectedEstablishments: string[];
}

export const EstabSelectionTree: React.FC<IEstabTreeSelectionProps> = ({
  establishments,
  onChangeEstabs,
  selectedEstablishments,
}) => {
  const [estabsFromDatabase, setEstabsFromDatabase] = useState<
    IEstablishmentTree[]
  >([]);
  const [estabsFromUserChoice, setEstabsFromUserChoice] = useState<string[]>(
    [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    setExpanded(
      selectedEstablishments.map((selected: {toString: () => any}) =>
        selected.toString(),
      ),
    );
  }, [selectedEstablishments]);

  function getChildByCodigoEstruturado(
    estabelecimentos: IEstablishmentTree,
    codigoEstruturado: string,
  ): string[] {
    let array: string[] = [];

    function getAllChild(estabelecimentos: IEstablishmentTree | null) {
      if (estabelecimentos === null) return [];
      array.push(estabelecimentos.CodigoEstruturado);
      if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeByCodigoEstruturado(
      estabelecimentos: IEstablishmentTree,
      codigoEstruturado: string,
    ) {
      if (estabelecimentos.CodigoEstruturado === codigoEstruturado) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (
            !!getNodeByCodigoEstruturado(estabelecimento, codigoEstruturado)
          ) {
            result = getNodeByCodigoEstruturado(
              estabelecimento,
              codigoEstruturado,
            );
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(
      getNodeByCodigoEstruturado(estabelecimentos, codigoEstruturado),
    );
  }

  function getOnChange(checked: boolean, estabelecimentos: IEstablishmentTree) {
    const allNode: string[] = getChildByCodigoEstruturado(
      estabelecimentos,
      estabelecimentos.CodigoEstruturado,
    );

    let array = checked
      ? [...estabsFromUserChoice, ...allNode]
      : estabsFromUserChoice.filter(
          (value: string) => !allNode.includes(value),
        );

    array = array.filter((v: any, i: any) => array.indexOf(v) === i);

    setEstabsFromUserChoice(array);
    onChangeEstabs(array);
  }

  useEffect(() => {
    setEstabsFromDatabase(establishments);
    setEstabsFromUserChoice(selectedEstablishments);

    if (establishments.length > 0) {
      const allNodes: string[] = getChildByCodigoEstruturado(
        establishments[0],
        String(establishments[0].CodigoEstruturado),
      );
      setExpanded(allNodes.map((node) => node.toString()));
    }

    // eslint-disable-next-line
  }, [establishments, selectedEstablishments]);

  const renderizarEstabelecimentos = (estabelecimentos: IEstablishmentTree) => (
    <TreeItem
      key={estabelecimentos.CodigoEstruturado}
      nodeId={estabelecimentos.CodigoEstruturado}
      label={
        <Box>
          <Checkbox
            edge="start"
            checked={estabsFromUserChoice.includes(
              estabelecimentos.CodigoEstruturado,
            )}
            onChange={(event) =>
              getOnChange(event.currentTarget.checked, estabelecimentos)
            }
            onClick={(e) => e.stopPropagation()}
          />
          {`${estabelecimentos.CodigoEstruturado} - ${estabelecimentos.NomeEstabelecimento} `}
        </Box>
      }>
      {Array.isArray(estabelecimentos.EstabelecimentosFilhos)
        ? estabelecimentos.EstabelecimentosFilhos.map((estabelecimento) =>
            renderizarEstabelecimentos(estabelecimento),
          )
        : null}
    </TreeItem>
  );

  return (
    <Box>
      <TreeView
        expanded={expanded}
        selected={selected}
        handleToggle={handleToggle}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}>
        {estabsFromDatabase?.length > 0 ? (
          estabsFromDatabase?.map((estab: any) =>
            renderizarEstabelecimentos(estab),
          )
        ) : (
          <Box padding={2}>
            <Typography color="secondary">
              Nenhum estabelecimento encontrado...
            </Typography>
          </Box>
        )}
      </TreeView>
    </Box>
  );
};
