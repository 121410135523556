import React from 'react';
import {AutocompleteProps, Theme} from '@mui/material';
import {Autocomplete} from '@mui/lab';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));
export const AutoComplete: React.FC<AutocompleteProps<any, any, any, any>> = ({
  ...props
}) => {
  return <Autocomplete classes={useStyles()} {...props} />;
};
