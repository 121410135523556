import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors/ErrorInterceptor';

export interface IMatrizCurricularPorCurso {
  Id: string;
  Descricao: string;
  CodigoCurso: string;
  Selecionado: boolean;
}

export interface ICursosCurriculos {
  Id: string;
  DescricaoCurso: string;
  Selecionado: boolean;
  MatrizCurricularPorCurso: IMatrizCurricularPorCurso[];
}

export interface ICursosCurriculosPost {
  IdPublicoAlvo: string;
  IdsValoresSelecionados: [];
  Chave: string;
  Filtros: [
    {Chave: 'cursos'; IdsValoresSelecionados: string[]},
    {Chave: 'curriculos'; IdsValoresSelecionados: string[]},
  ];
}

const getById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<ICursosCurriculos[]>> => {
  try {
    const {data} = await Api.get(`/CursosCurriculos/${idPublicoAlvo}`);

    const response: IResultOfAction<ICursosCurriculos[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  cursosCurriculosPost: ICursosCurriculosPost,
): Promise<IResultOfAction<ICursosCurriculosPost>> => {
  try {
    const {data} = await Api.post('/CursosCurriculos', cursosCurriculosPost);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const CursosCurriculosService = {
  getById,
  post,
};
