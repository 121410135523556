import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTitle} from '../../shared/hooks';
import {ActionButtons, NotFoundText} from 'shared/components';
import {
  Container,
  Grid,
  FormControl,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TextField,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import {
  GrupoPapeisService,
  IGrupoPapeis,
} from 'shared/services/api/grupo-papeis/GrupoPapeisList';
import {Chip} from './components/Chip';
import {feedback} from 'shared/services';

export const GrupoPapeisList: React.FC = () => {
  const {setTitle} = useTitle();
  const [searchInputValue, setSearchInputValue] = useState('');
  const navigate = useNavigate();
  const [gruposPapeis, setGruposPapeis] = useState<IGrupoPapeis[]>([]);
  const [gruposPapeisForSearch, setGruposPapeisForSearch] = useState<
    IGrupoPapeis[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTitle('Grupo de papéis');
  }, [setTitle]);

  useEffect(() => {
    const searchResults = gruposPapeisForSearch.length
      ? gruposPapeisForSearch.filter(
          (item) =>
            item.Nome.toLowerCase().indexOf(searchInputValue.toLowerCase()) >
            -1,
        )
      : [];

    setGruposPapeis(searchResults);
  }, [searchInputValue, gruposPapeisForSearch]);

  useEffect(() => {
    GrupoPapeisService.getAll().then((data) => {
      if (data.Success) {
        setGruposPapeis(data.Data);
        setGruposPapeisForSearch(data.Data);
      } else {
        feedback(data.Message || 'Erro ao carregar grupo de papéis.', 'error');
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <Container>
      <Grid container item xs={12} spacing={3}>
        <Grid container item xs={12} spacing={0}>
          <Grid container item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar por nome do grupo de papéis."
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Grupo
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Papel ERP
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width={100}
                    align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : gruposPapeis?.length ? (
                  gruposPapeis?.map((grupo) => (
                    <TableRow hover key={grupo.IdGrupoPapel}>
                      <TableCell component="th" scope="row">
                        {grupo.Nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {grupo.PapeisErp.slice(0, 2).map((papelErp, i) => {
                          if (i + 1 === 2) {
                            return (
                              <span key={papelErp.Id}>, {papelErp.Papel}</span>
                            );
                          } else {
                            return (
                              <span key={papelErp.Id}>{papelErp.Papel}</span>
                            );
                          }
                        })}
                        {grupo.PapeisErp.length > 2 && (
                          <Chip
                            length={grupo.PapeisErp.length - 2}
                            textList={grupo.PapeisErp.map((value, index) => {
                              if (index > 1) {
                                if (index === grupo.PapeisErp.length - 1) {
                                  return value.Papel + '.';
                                }
                                return value.Papel + ', ';
                              } else {
                                return '';
                              }
                            })}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        width={100}
                        align="right">
                        <ActionButtons
                          rowId={grupo.IdGrupoPapel}
                          canEdit={true}
                          onClickEdit={() =>
                            navigate(
                              `/grupo-de-papeis/detail/${grupo.IdGrupoPapel}`,
                            )
                          }
                          onClickDelete={() => {}}
                          hideDeleteButton
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};
