import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IEstabelecimento {
  CodigoEstruturado: string;
  IdEstabelecimento: number;
  NomeEstabelecimento: string;
  Sigla: string;
}

export interface IEstablishmentTree {
  IdEstabelecimento: number;
  NomeEstabelecimento: string;
  Sigla: string;
  CodigoEstruturado: string;
  CodigoPai: string;
  EstabelecimentosFilhos: IEstablishmentTree[];
  CodigoEstruturadoInt: number;
}

const getAll = async (): Promise<IResultOfAction<IEstabelecimento[]>> => {
  try {
    const {data} = await Api.get('estabelecimentos');

    const response: IResultOfAction<IEstabelecimento[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAllTreeFormat = async (): Promise<
  IResultOfAction<IEstablishmentTree[]>
> => {
  try {
    const {data} = await Api.get('Estabelecimentos/listaemarvore');

    const response: IResultOfAction<IEstablishmentTree[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const EstabelecimentosService = {
  getAll,
  getAllTreeFormat,
};
