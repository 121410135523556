import {Box, Dialog, DialogContent} from '@mui/material';
import React from 'react';
import {FeedBack} from '../Feedback';
import {feedback} from 'shared/services/alert/AlertService';

interface ModalFeedBackProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ModalFeedBack: React.FC<ModalFeedBackProps> = ({
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Box padding={0}>
          <FeedBack
            idProduto="quest"
            textoTitulo="Envia seu feedback sobre o GVQuest"
            onErro={(id) => feedback(id, 'error')}
            onEnviarFeedback={(id) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
              feedback(id, 'success'), handleClose();
            }}
            onFecharModal={() => handleClose()}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
