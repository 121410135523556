import {Card, List, Divider, ListItem} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const TransferListSkeleton: React.FC = () => {
  return (
    <Card>
      <List dense component="div" disablePadding>
        <ListItem style={{paddingTop: '12px', paddingBottom: '12px'}}>
          <Skeleton width="100%" height={45} />
        </ListItem>
        <Divider />
        <ListItem>
          <Skeleton width="100%" height={45} />
        </ListItem>
        <ListItem>
          <Skeleton width="100%" height={45} />
        </ListItem>
      </List>
    </Card>
  );
};
