import React from 'react';
import {Box, Drawer, Hidden, Theme} from '@mui/material';
import {Header, MenuContent} from '../components';
import {useDrawer} from '../hooks';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.background.paper} !important`,
    overflow: 'inherit',
    border: 'none',
    width: (props: {drawerWidth: number}) => props.drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: (props: {drawerWidth: number; hover: boolean}) =>
        props.hover ? props.drawerWidth : 72,
      transition: 'all 0.3s ease 0.01s',
    },
  },
  pageContainer: {
    transition: 'all 0.3s',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 72,
      width: 'calc(100% - 72px)',
    },
    margin: '96px 0',
  },
  hover: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 280,
      width: 'calc(100% - 280px)',
    },
  },
  drawerWidth: {
    width: (props: {drawerWidth: number}) => props.drawerWidth,
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.background.paper} !important`,
    overflow: 'inherit',
    [theme.breakpoints.up('sm')]: {
      width: (props: {drawerWidth: number; hover: boolean}) =>
        props.hover ? props.drawerWidth : 72,
      transition: 'all 0.3s ease 0.01s',
    },
    border: 'none',
  },
  childrenContainer: {
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
  },
}));

export const LayoutBase: React.FC = ({children}) => {
  const {
    isMobileDrawerOpen,
    drawerWidth,
    hover,
    toggleMobileDrawer,
    setHover,
    clicked,
  } = useDrawer();
  const classes = useStyles({hover, drawerWidth});

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="stretch"
      flexGrow={1}
      flex={1}>
      <div
        onMouseEnter={() => {
          !clicked && setHover(true);
        }}
        onMouseLeave={() => {
          !clicked && setHover(false);
        }}>
        <nav>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              open={isMobileDrawerOpen}
              onClose={toggleMobileDrawer}
              ModalProps={{keepMounted: true}}
              classes={{paper: classes.drawerPaper}}>
              <MenuContent />
            </Drawer>
          </Hidden>

          <Hidden xsDown implementation="css">
            <Drawer variant="permanent" classes={{paper: classes.drawerPaper}}>
              <MenuContent />
            </Drawer>
          </Hidden>
        </nav>
      </div>

      <Box
        flex={1}
        className={`${classes.pageContainer} ${hover ? classes.hover : ''}`}>
        <Header />
        <Box className={classes.childrenContainer}>{children}</Box>
      </Box>
    </Box>
  );
};
