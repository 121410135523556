export const Environment = {
  ID_TENANT: 'area87centris2',

  LIMITE_DE_LINHAS: 10,
  URL_API:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/publicoalvo/api/v1`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/publicoalvo/api/v1`
      : `https://api.gvdasa.com.br/publicoalvo/api/v1`,
  API_GATEWAY:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br`
      : `https://api.gvdasa.com.br`,
  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? 'https://area87centris2-dev.publicoalvo.gvdasa.com.br/'
    : '',
  URL_WC_FEEDABACK:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-feedbacks.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-feedbacks-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-feedbacks-dev.gvdasa.com.br'
      : 'https://wc-feedbacks-dev.gvdasa.com.br',

  URL_WC_AGENDAMENTOS:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-agendamentos.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-agendamentos-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev' ||
        window.location.host.includes('localhost')
      ? 'https://wc-agendamentos-dev.gvdasa.com.br'
      : 'https://wc-agendamentos-dev.gvdasa.com.br',

  URL_API_AGENDAMENTO:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/agendamentos/api/v1/Agendamento`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/agendamentos/api/v1/Agendamento`
      : `https://api.gvdasa.com.br/agendamentos/api/v1/Agendamento`,
};
