import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IPapeisERP {
  Id: string;
  Papel: string;
}

export interface IGrupoPapeis {
  IdGrupoPapel: string;
  Nome: string;
  NomeInterno: string;
  PermiteExclusao: boolean;
  PapeisErp: IPapeisERP[];
}

const getAll = async (): Promise<IResultOfAction<IGrupoPapeis[]>> => {
  try {
    const {data, headers} = await Api.get('/GruposPapeis');
    const ERPnew = headers.erpintegracao;
    const response: IResultOfAction<IGrupoPapeis[]> = {
      Success: true,
      Message: '',
      Data: data,
      ERP: ERPnew,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAllPapeisErp = async (): Promise<IResultOfAction<IPapeisERP[]>> => {
  try {
    const {data} = await Api.get('/PapeisErp');

    const response: IResultOfAction<IPapeisERP[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getById = async (
  idGrupoPapel: string,
): Promise<IResultOfAction<IGrupoPapeis>> => {
  try {
    const {data} = await Api.get('/GruposPapeis/' + idGrupoPapel);

    const response: IResultOfAction<IGrupoPapeis> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const GrupoPapeisService = {
  getAll,
  getById,
  getAllPapeisErp,
};
