import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {CssBaseline} from '@mui/material';
import {AppRoutes} from './routes';
import {AlertComponent, ConfirmationDialogComponent} from './shared/components';

import {DrawerProvider, ThemesProvider, TitleProvider} from './shared/contexts';
import {UserProvider} from 'shared/contexts/User';
import {SplashScreen} from 'shared/components/splash-screen/SplashScreen';
import {NotificacoesProvider} from 'shared/contexts/Notifications';
import {Environment} from 'shared/Env';
import {LayoutBase} from 'shared/layouts';

export const App: React.FC = () => {
  useEffect(() => {
    const scriptAgendameto = document.createElement('script');
    scriptAgendameto.type = 'module';
    scriptAgendameto.src = `${Environment.URL_WC_AGENDAMENTOS}`;
    scriptAgendameto.crossOrigin = 'anonymous';

    const scriptFeedBack = document.createElement('script');
    scriptFeedBack.type = 'module';
    scriptFeedBack.src = `${Environment.URL_WC_FEEDABACK}`;
    scriptFeedBack.crossOrigin = 'anonymous';

    document.body.appendChild(scriptFeedBack);
    document.body.appendChild(scriptAgendameto);

    return () => {
      document.body.appendChild(scriptFeedBack);
      document.body.removeChild(scriptAgendameto);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemesProvider>
        <NotificacoesProvider>
          <UserProvider>
            <SplashScreen>
              <BrowserRouter>
                <TitleProvider>
                  <DrawerProvider>
                    <LayoutBase>
                      <AppRoutes />
                      <AlertComponent />
                      <ConfirmationDialogComponent />
                    </LayoutBase>
                  </DrawerProvider>
                </TitleProvider>
              </BrowserRouter>
            </SplashScreen>
          </UserProvider>
        </NotificacoesProvider>
      </ThemesProvider>
    </>
  );
};

export default App;
