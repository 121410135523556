import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface INivelEnsino {
  Id: number;
  DescricaoNivel: string;
  Selecionado: boolean;
}

export interface INivelEnsinoPost {
  Chave: string;
  IdsValoresSelecionados: number[];
  IdPublicoAlvo: string;
}

const getById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<INivelEnsino[]>> => {
  try {
    const {data} = await Api.get(`NiveisEnsino/${idPublicoAlvo}`);

    const response: IResultOfAction<INivelEnsino[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  niveisEnsino: INivelEnsinoPost,
): Promise<IResultOfAction<INivelEnsinoPost>> => {
  try {
    const {data} = await Api.post(`NiveisEnsino`, niveisEnsino);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const NiveisEnsinoService = {
  getById,
  post,
};
