import React from 'react';

import {Chip as MuiChip} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {Tooltip} from 'shared/components';

interface IChipProps {
  length: number;
  textList: string[];
}

export const Chip: React.FC<IChipProps> = ({length, textList}) => {
  return (
    <Tooltip text={textList.join('')}>
      <MuiChip icon={<AddIcon />} label={length} size="small" />
    </Tooltip>
  );
};
