import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IGrupoPapeisDetail {
  Id: string;
  PapeisERP: Array<string>;
}

const put = async (
  grupoPapeis: IGrupoPapeisDetail,
): Promise<IResultOfAction<IGrupoPapeisDetail>> => {
  try {
    const {data} = await Api.put(
      `/GruposPapeis/${grupoPapeis.Id}`,
      grupoPapeis,
    );

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const GrupoPapeisDetailService = {
  put,
};
