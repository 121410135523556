import {useTheme} from '@mui/material';
import React, {useEffect, useRef} from 'react';

interface IComponenteAgendadorProps {
  topico: string;
  idTarefa: string;
  idClient: string;
  nomeTarefa: string;
  descricao: string;
  onAgendaSalva?: (id: string) => void;
  onErro?: (id: string) => void;
  tipoOcorrencia?: 'unica' | 'recorrente' | 'dinamica';
  fuso: string;
  idAgendamento: string;
}

export const Agendador: React.FC<IComponenteAgendadorProps> = ({
  topico,
  idTarefa,
  idClient,
  nomeTarefa,
  onAgendaSalva,
  onErro,
  descricao,
  tipoOcorrencia,
  fuso,
  idAgendamento,
}) => {
  const elementRef = useRef<HTMLElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const onAgendaSalvaListener = (event: any) =>
      onAgendaSalva && onAgendaSalva(event.detail);
    const error = (event: any) => onErro && onErro(event.detail);
    const ref = elementRef.current;

    if (ref) {
      ref.addEventListener('agendaSalva', onAgendaSalvaListener);
      ref.addEventListener('erro', error);

      return () => {
        ref?.removeEventListener('agendaSalva', onAgendaSalvaListener);
        ref?.removeEventListener('erro', error);
      };
    }
  }, [onAgendaSalva, onErro]);

  return React.createElement('componente-agendador', {
    ref: elementRef,
    topico: topico,
    tenant: 'tenant',
    'id-tarefa': idTarefa,
    'id-client': idClient,
    'nome-tarefa': nomeTarefa,
    'tipo-ocorrencia': tipoOcorrencia,
    descricao: descricao,
    fuso: fuso,
    'id-agendamento': idAgendamento,

    style: {
      '--color-primary': `${theme.palette.primary.main} !important`,
    },
  });
};
