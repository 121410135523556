import React, { useState, createContext, useCallback } from "react";

import mockMenuOptions from './mock/mockMenuOptions.json';

interface IMenuOption {
  key: string;
  path: string;
  name: string;
  icon: string;
}

interface DrawerContextData {
  setHover(value: boolean): void;
  isMobileDrawerOpen: boolean;
  toggleMobileDrawer(): void;
  menuOptions: IMenuOption[];
  closeMobileDrawer(): void;
  drawerWidth: number;
  hover: boolean;
  clicked: boolean;
  setClicked(value: boolean): void;
}

export const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

export const DrawerProvider: React.FC = ({ children }) => {
  const [menuOptions] = useState<IMenuOption[]>(mockMenuOptions.map(option => option));
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [drawerWidth] = useState(280);
  const [clicked, setClicked] = useState(false);

  const toggleMobileDrawer = useCallback(() => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  }, [isMobileDrawerOpen]);

  const closeMobileDrawer = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, []);

  return (
    <DrawerContext.Provider value={{ isMobileDrawerOpen, drawerWidth, hover, menuOptions, setHover, toggleMobileDrawer, closeMobileDrawer, clicked, setClicked }}>
      {children}
    </DrawerContext.Provider>
  );
};
