import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

import {useTitle} from 'shared/hooks';
import {ListaDeAgendamentos} from 'shared/components/lista-de-agendamentos/ListaDeAgendamentos';
import {Card} from '@mui/material';

export const Recorrencia: React.FC = () => {
  const {setTitle} = useTitle();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle('Agendamento');
  }, [setTitle]);

  return (
    <Card>
      <ListaDeAgendamentos
        idClient={'publicoalvo'}
        fuso={'GMT-3'}
        onEditById={(id: any) => {
          navigate(`/recorrencia/${id}`);
        }}
      />
    </Card>
  );
};
