import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface INotificacoes {
  Id: string;
  AtualizadoEm: string;
  Status: string;
  IdPublicoAlvo: string;
  Lido: boolean;
  IdUsuario: string;
  Nome: string;
}

const getPublicoAlvoStatus = async (): Promise<
  IResultOfAction<INotificacoes[]>
> => {
  try {
    const {data} = await Api.get(`/Notificacoes`);

    const response: IResultOfAction<INotificacoes[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const setNotificationAsRead = async (
  idNotificacao: string,
): Promise<IResultOfAction<any>> => {
  try {
    const putObject = {
      Id: idNotificacao,
    };

    const {data} = await Api.put(`/Notificacoes/${idNotificacao}`, putObject);

    const response: IResultOfAction<any> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const NotificacoesService = {
  getPublicoAlvoStatus,
  setNotificationAsRead,
};
