import {useCallback, useState, useMemo, useEffect} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {Container} from '@mui/material';
import {Stepper} from 'shared/components/stepper/Stepper';
import {
  Configuracoes,
  Niveis,
  Cursos,
  Periodos,
  Disciplinas,
  Turmas,
  Pessoas,
} from './components';
import {useUser, useTitle} from 'shared/hooks';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';

export const PublicoAlvoDetail: React.FC = () => {
  const {setTitle} = useTitle();
  const {isCollege} = useUser();
  const {id = '0'} = useParams<'id'>();
  const navigate = useNavigate();
  const [idPublicoAlvo, setIdPublicoAlvo] = useState(id);
  const [activeStep, setActiveStep] = useState(0);
  const [isRecargaAutomatica, setIsRecargaAutomatica] = useState(true);
  const [isPublicoSemDisciplinas, setIsPublicoSemDisciplinas] = useState(false);
  //eslint-disable-next-line
  const [isReprocessamentoAutomatico, setIsReprocessamentoAutomatico] =
    useState(true);
  const [hasNecessaryData, setHasNecessaryData] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (id === 'new') {
      setTitle('Novo público-alvo');
    } else {
      setIdPublicoAlvo(id);
      PublicoAlvoDetailsService.getById(id).then((result) => {
        if (result.Success) {
          setTitle(`Público-alvo: ${result.Data.Nome}`);
        }
      });
    }
  }, [location, id, setTitle]);

  const steps = useMemo(() => {
    if (isCollege) {
      return [
        'Configurações',
        'Períodos',
        'Níveis',
        'Cursos',
        'Turmas',
        'Disciplinas',
        'Pessoas',
      ];
    }

    return [
      'Configurações',
      'Períodos',
      'Níveis',
      'Cursos',
      'Disciplinas',
      'Turmas',
      'Pessoas',
    ];
  }, [isCollege]);

  const handleBack = useCallback(() => {
    setActiveStep((activeStep) => activeStep - 1);
  }, [setActiveStep]);

  const handleNext = useCallback(() => {
    setActiveStep((activeStep) => activeStep + 1);
  }, [setActiveStep]);

  const handleLastStep = useCallback(() => {
    setActiveStep(steps.length - 1);
  }, [steps.length]);

  const handleFinish = useCallback(() => {
    navigate('/publico-alvo');
  }, [navigate]);

  const getStepContent = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return (
            <Configuracoes
              nextStep={handleNext}
              lastStep={handleLastStep}
              idPublicoAlvo={idPublicoAlvo}
              setIdPublicoAlvo={setIdPublicoAlvo}
              setHasNecessaryData={setHasNecessaryData}
              onCheckRecargaAutomatica={setIsRecargaAutomatica}
              onCheckPublicoSemDisciplinas={setIsPublicoSemDisciplinas}
              onCheckReprocessamento={setIsReprocessamentoAutomatico}
            />
          );
        case 1:
          return (
            <Periodos
              nextStep={handleNext}
              previousStep={handleBack}
              idPublicoAlvo={idPublicoAlvo}
            />
          );
        case 2:
          return (
            <Niveis
              idPublicoAlvo={idPublicoAlvo}
              previousStep={handleBack}
              nextStep={handleNext}
            />
          );
        case 3:
          return (
            <Cursos
              idPublicoAlvo={idPublicoAlvo}
              previousStep={handleBack}
              nextStep={handleNext}
              isPublicoSemDisciplinas={isPublicoSemDisciplinas}
              lastStep={handleLastStep}
            />
          );
        case 4:
          if (isCollege) {
            return (
              <Turmas
                idPublicoAlvo={idPublicoAlvo}
                previousStep={handleBack}
                nextStep={handleNext}
              />
            );
          }
          return (
            <Disciplinas
              idPublicoAlvo={idPublicoAlvo}
              previousStep={handleBack}
              nextStep={handleNext}
            />
          );
        case 5:
          if (isCollege) {
            return (
              <Disciplinas
                idPublicoAlvo={idPublicoAlvo}
                previousStep={handleBack}
                nextStep={handleNext}
              />
            );
          }
          return (
            <Turmas
              idPublicoAlvo={idPublicoAlvo}
              previousStep={handleBack}
              nextStep={handleNext}
            />
          );
        case 6:
          return (
            <Pessoas
              idPublicoAlvo={idPublicoAlvo}
              previousStep={handleBack}
              firstStep={() => setActiveStep(0)}
              finish={handleFinish}
              hasNecessaryData={hasNecessaryData}
              isRecargaAutomatica={isRecargaAutomatica}
            />
          );

        default:
          return 'Outras etapas...';
      }
    },
    [
      isCollege,
      handleNext,
      handleBack,
      handleFinish,
      idPublicoAlvo,
      handleLastStep,
      hasNecessaryData,
      isRecargaAutomatica,
    ],
  );

  return (
    <Container>
      <Stepper steps={steps} activeStep={activeStep} />
      {getStepContent(activeStep)}
    </Container>
  );
};
