import React from 'react';
import {Box} from '@mui/material';
import {Skeleton} from '@mui/lab';

export const EstabSelectionTreeSkeleton: React.FC = () => {
  return (
    <>
      <Box marginBottom={1} width={400}>
        <Skeleton variant="text" width="50" />
      </Box>
      <Box marginBottom={1} width={300}>
        <Skeleton variant="text" width="50" />
      </Box>
      <Box marginBottom={1} width={200}>
        <Skeleton variant="text" width="50" />
      </Box>
      <Box marginBottom={1} width={200}>
        <Skeleton variant="text" width="50" />
      </Box>
      <Box marginBottom={1} width={300}>
        <Skeleton variant="text" width="50" />
      </Box>
    </>
  );
};
