import { Subject } from 'rxjs';

export type ConfirmationDialogTypes = "warning" | "delete" | "confirmation" | undefined

interface IConfirmationDialog {
    type: ConfirmationDialogTypes;
    onConfirm?: Function;
    onCancel?: Function;
    message: string;
}

const confirmationDialogSubject = new Subject<IConfirmationDialog>();

export const ConfirmationDialogService = confirmationDialogSubject.asObservable();

export const confirm = (message: string, type: ConfirmationDialogTypes, onConfirm?: Function, onCancel?: Function) => {
    confirmationDialogSubject.next({
        onConfirm,
        onCancel,
        message,
        type,
    });
}
