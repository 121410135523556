import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface SituacaoTurma {
  IdSituacaoTurma: string;
  Selecionado: boolean;
  SituacaoTurma: string;
}

export interface ITurmas {
  Id: string;
  DescricaoTurma: string;
  Selecionado: boolean;
  IdSituacaoTurma?: string;
}

export interface ITurmasPost {
  Chave: string;
  IdsValoresSelecionados: string[];
  IdPublicoAlvo: string;
}

const getById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<ITurmas[]>> => {
  try {
    const {data} = await Api.get(`Turmas/${idPublicoAlvo}`);

    const response: IResultOfAction<ITurmas[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getSituacaoTurmaById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<SituacaoTurma[]>> => {
  try {
    const {data} = await Api.get(`SituacoesTurma/${idPublicoAlvo}`);

    const response: IResultOfAction<SituacaoTurma[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  turmas: ITurmasPost,
): Promise<IResultOfAction<ITurmasPost>> => {
  try {
    const {data} = await Api.post(`Turmas`, turmas);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const postSituacoesTurma = async (
  situacoesTurma: ITurmasPost,
): Promise<IResultOfAction<ITurmasPost>> => {
  try {
    const {data} = await Api.post(`SituacoesTurma`, situacoesTurma);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const TurmasService = {
  getById,
  post,
  postSituacoesTurma,
  getSituacaoTurmaById,
};
