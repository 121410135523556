import {Api} from 'shared/services/axios-config';
import {IEstabelecimento} from 'shared/services/api/estabelecimentos/Estabelecimentos';
import {IGrupoPapeis} from 'shared/services/api/grupo-papeis/GrupoPapeisList';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IPublicoAlvo {
  Id: string;
  Nome: string;
  SistemaChamada: string;
  PermiteEdicao: boolean;
  RecargaAutomatica: boolean;
  ReprocessamentoAutomatico: boolean;
  Estabelecimentos: IEstabelecimento[];
  GruposPapeis: IGrupoPapeis[];
  Status?:
    | 'EM_ELABORACAO'
    | 'AGUARDANDO_PROCESSAMENTO'
    | 'EM_PROCESSAMENTO'
    | 'CONCLUIDO'
    | 'ERRO_PROCESSAMENTO'
    | string;
}

export interface IPublicoHistory {
  Id: string;
  IdPublicoAlvo: string;
  EtapaDeProcessamento: string;
  Status: string;
  MensagemDeErro: string;
  DataHora: string;
}

const getAll = async (): Promise<IResultOfAction<IPublicoAlvo[]>> => {
  try {
    const {data} = await Api.get('/PublicosAlvo');

    const response: IResultOfAction<IPublicoAlvo[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const deleteById = async (id: string): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.delete(`/PublicosAlvo/${id}`);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getHistoryById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<IPublicoHistory[]>> => {
  try {
    const {data} = await Api.get(`/PublicosAlvo/${idPublicoAlvo}/Historico`);

    const response: IResultOfAction<IPublicoHistory[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PublicoAlvoListService = {
  getAll,
  deleteById,
  getHistoryById,
};
