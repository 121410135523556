import React from 'react';

import {Box, Typography} from '@mui/material';

interface INotFoundTextProps {
  text?: string;
}

export const NotFoundText: React.FC<INotFoundTextProps> = ({
  text = 'Nenhum registro encontrado...',
}) => {
  return (
    <Box padding={2}>
      <Typography color="secondary">{text}</Typography>
    </Box>
  );
};
