import {createTheme} from '@mui/material';
import {ptBR} from '@mui/material/locale';

export const DarkTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#018781 ',
      },
      secondary: {
        main: '#01B3AA ',
      },
      background: {
        default: '#1d1d1d',
        paper: '#3e3e3e',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    props: {
      MuiTable: {
        // size: 'small',
      },
      MuiTableCell: {
        style: {
          border: 'thin',
        },
      },
    },
  },
  ptBR,
);
