import React, {useState, useRef, useCallback} from 'react';
import {
  Box,
  IconButton,
  useTheme,
  Popover,
  Badge,
  Hidden,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  Notifications,
  VisibilityOutlined,
  DraftsRounded,
} from '@mui/icons-material/';
import {useNotification} from 'shared/hooks';
import {format} from 'date-fns';
import {NotificacoesService} from 'shared/services/api/notificacoes/Notificacoes';
import {Link} from 'react-router-dom';

export const Notification: React.FC = () => {
  const {totalNotificacoes, notificacoes, getNotificacoes, carregandoNotif} =
    useNotification();
  const notificationRef = useRef(null);
  const theme = useTheme();
  const [anchorElement, setAnchorElement] = useState(null);
  const isPopoverOpen = Boolean(anchorElement);

  const handleOpenPopover = () => {
    setAnchorElement(notificationRef.current);
  };

  const handleClosePopover = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const handleRefreshNotifications = useCallback(
    (notificacaoId: string) => {
      NotificacoesService.setNotificationAsRead(notificacaoId).then(
        (result) => {
          if (result.Success) {
            getNotificacoes();
          }
        },
      );
    },
    [getNotificacoes],
  );

  return (
    <Hidden xsDown>
      <Box>
        <IconButton
          style={{color: theme.palette.common.white}}
          ref={notificationRef}
          onClick={handleOpenPopover}>
          <Badge badgeContent={totalNotificacoes} color={'error'}>
            <Notifications />
          </Badge>
        </IconButton>
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorElement}
          onClose={handleClosePopover}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Box width={'400px'} maxHeight={'500px'}>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'left'}
              gap={1}
              padding={1}
              marginBottom={notificacoes.length > 0 ? 1 : 0}
              bgcolor={'#01b3aa15'}>
              <Notifications color={'primary'} />
              <Typography variant="body1" color="primary">
                {totalNotificacoes === 0
                  ? 'Nenhuma notificação'
                  : `${totalNotificacoes} notificações não lidas`}
              </Typography>
            </Box>
            <Box width={'100%'} p={1} style={{position: 'relative'}}>
              {carregandoNotif && (
                <Box
                  p={1}
                  width={'calc(100% - 16px)'}
                  height={'100%'}
                  position={'absolute'}
                  top={0}
                  left={8}
                  bgcolor={'rgba(54, 135, 129, 0.3)'}>
                  <Box
                    height={'100%'}
                    maxHeight={'500px'}
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <CircularProgress size={30} />
                  </Box>
                </Box>
              )}
              {notificacoes.length > 0 ? (
                notificacoes.map((notificacao, i) => (
                  <Box
                    key={`notificacao-publico-alvo-${notificacao.Id}-${i}`}
                    marginBottom={2}
                    borderRadius={2}>
                    <Card>
                      <CardHeader
                        title={
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            <Typography variant={'body1'}>
                              <strong>Processamento finalizado!</strong>
                            </Typography>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              gap={1}>
                              <Tooltip
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                  if (!carregandoNotif) {
                                    handleRefreshNotifications(notificacao.Id);
                                  }
                                }}
                                title={'Marcar como lido.'}>
                                <DraftsRounded color="primary" />
                              </Tooltip>
                              <Link
                                replace
                                style={{
                                  textDecoration: 'none',
                                  display: 'flex',
                                }}
                                to={{
                                  pathname: `publico-alvo/detail/${notificacao.IdPublicoAlvo}`,
                                  search: '?lastStep=true',
                                }}
                                onClick={() => {
                                  if (!carregandoNotif) {
                                    handleRefreshNotifications(notificacao.Id);
                                  }
                                }}>
                                <Tooltip title={'Visualizar público-alvo.'}>
                                  <VisibilityOutlined color="primary" />
                                </Tooltip>
                              </Link>
                            </Box>
                          </Box>
                        }
                      />
                      <CardContent>
                        <Box width={'100%'}>
                          <Typography variant={'body2'}>
                            O processamento do público-alvo{' '}
                            <strong>{notificacao.Nome}</strong> foi concluído
                            com sucesso!
                          </Typography>
                        </Box>
                        <Box width={'100%'} textAlign={'right'}>
                          <Typography variant={'body2'} color={'textSecondary'}>
                            {`${format(
                              new Date(notificacao.AtualizadoEm),
                              'dd/MM/yyyy',
                            )}`}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ))
              ) : (
                <Box width={'100%'} p={1}>
                  <Typography variant={'body2'} color={'primary'}>
                    Nenhuma notificação para visualização...
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Popover>
      </Box>
    </Hidden>
  );
};
