import React, {useEffect, useCallback, useState, useMemo} from 'react';
import {
  Box,
  Card,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Paper,
  FormControl,
  Icon,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  DialogActions,
  DialogContent,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {ActionTab, TransferListSkeleton, NotFoundText} from 'shared/components';
import {
  VirtualizedTransferList,
  IVirtualizedTransferListItem,
} from 'shared/components/transfer-list/VirtualizedTransferList';
import {feedback} from 'shared/services';
import {useDebounce} from 'shared/hooks';
import {
  ICursosCurriculos,
  IMatrizCurricularPorCurso,
  ICursosCurriculosPost,
  CursosCurriculosService,
} from 'shared/services/api/cursos-curriculos/CursosCurriculos';
import {useLocation, useNavigate} from 'react-router-dom';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  isPublicoSemDisciplinas: boolean;
  lastStep: () => void;
  nextStep: () => void;
}

export const Cursos: React.FC<IStepProps> = ({
  idPublicoAlvo,
  isPublicoSemDisciplinas,
  lastStep,
  previousStep,
  nextStep,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const {debounce} = useDebounce();
  const [options, setOptions] = useState<ICursosCurriculos[]>([]);
  const [isLoadingLeft, setIsLoadingLeft] = useState(true);
  const [isLoadingRight, setIsLoadingRight] = useState(true);
  const [searchLeft, setSearchLeft] = useState('');
  const [searchRight, setSearchRight] = useState('');
  const [isInputLoadingLeft, setIsInputLoadingLeft] = useState(false);
  const [isInputLoadingRight, setIsInputLoadingRight] = useState(false);
  const [selectedItemsLeftIds, setSelectedItemsLeftIds] = useState<string[]>(
    [],
  );
  const [selectedChildrenLeftIds, setSelectedChildrenLeftIds] = useState<
    string[]
  >([]);
  const [expandedItemsLeftIds, setExpandedItemsLeftIds] = useState<string[]>(
    [],
  );
  const [selectedItemsRightIds, setSelectedItemsRightIds] = useState<string[]>(
    [],
  );
  const [selectedChildrenRightIds, setSelectedChildrenRightIds] = useState<
    string[]
  >([]);
  const [expandedItemsRightIds, setExpandedItemsRightIds] = useState<string[]>(
    [],
  );
  const location = useLocation();

  useEffect(() => {
    setIsLoadingLeft(true);
    setIsLoadingRight(true);
    CursosCurriculosService.getById(idPublicoAlvo).then((data) => {
      if (data.Success) {
        setOptions(data.Data);
        setIsLoadingLeft(false);
        setIsLoadingRight(false);
      } else {
        setIsLoadingLeft(false);
        setIsLoadingRight(false);
        feedback(
          data.Message || 'Erro ao carregar cursos e currículos.',
          'error',
        );
      }
    });
  }, [idPublicoAlvo, location]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const filterOptionsLeft: IVirtualizedTransferListItem[] = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    options
      .filter((option) => {
        const hasNotSelected = option.MatrizCurricularPorCurso.some(
          (child) => !child.Selecionado,
        );

        return !option.Selecionado || hasNotSelected;
      })
      .map((option) => {
        const children: IMatrizCurricularPorCurso[] =
          option.MatrizCurricularPorCurso.filter(
            (child) =>
              !child.Selecionado &&
              normalizeText(child.Descricao).includes(
                normalizeText(searchLeft),
              ),
          );

        return {...option, MatrizCurricularPorCurso: children};
      })
      .filter((option) => {
        const keepOnFilter = normalizeText(option.DescricaoCurso).includes(
          normalizeText(searchLeft),
        );
        const keepChildrenOnFilter = option.MatrizCurricularPorCurso.some(
          (child) =>
            normalizeText(child.Descricao).includes(normalizeText(searchLeft)),
        );

        return keepOnFilter || keepChildrenOnFilter;
      })
      .forEach((option) => {
        const isOptionExpanded = expandedItemsLeftIds.includes(option.Id);

        result.push({
          id: option.Id,
          label: option.DescricaoCurso,
          hasChildren: !!option.MatrizCurricularPorCurso.length,
          isExpanded: isOptionExpanded,
          isSelected: selectedItemsLeftIds.includes(option.Id),
          nivel: '1',
        });

        if (!isOptionExpanded) return;

        option.MatrizCurricularPorCurso.forEach((child) => {
          result.push({
            id: child.Id,
            label: child.Descricao,
            hasChildren: false,
            isExpanded: false,
            isSelected: selectedChildrenLeftIds.includes(child.Id),
            nivel: '2',
          });
        });
      });

    return result;
  }, [
    expandedItemsLeftIds,
    normalizeText,
    options,
    searchLeft,
    selectedChildrenLeftIds,
    selectedItemsLeftIds,
  ]);

  const filterOptionsRight: IVirtualizedTransferListItem[] = useMemo(() => {
    const result: IVirtualizedTransferListItem[] = [];

    options
      .filter((option) => {
        const hasSelected = option.MatrizCurricularPorCurso.some(
          (child) => child.Selecionado,
        );

        return option.Selecionado || hasSelected;
      })
      .map((option) => {
        const children: IMatrizCurricularPorCurso[] =
          option.MatrizCurricularPorCurso.filter(
            (child) =>
              child.Selecionado &&
              normalizeText(child.Descricao).includes(
                normalizeText(searchRight),
              ),
          );

        return {...option, MatrizCurricularPorCurso: children};
      })
      .filter((option) => {
        const keepOnFilter = normalizeText(option.DescricaoCurso).includes(
          normalizeText(searchRight),
        );
        const keepChildrenOnFilter = option.MatrizCurricularPorCurso.some(
          (child) =>
            normalizeText(child.Descricao).includes(normalizeText(searchRight)),
        );

        return keepOnFilter || keepChildrenOnFilter;
      })
      .forEach((option) => {
        const isOptionExpanded = expandedItemsRightIds.includes(option.Id);

        result.push({
          id: option.Id,
          label: option.DescricaoCurso,
          hasChildren: !!option.MatrizCurricularPorCurso.length,
          isExpanded: isOptionExpanded,
          isSelected: selectedItemsRightIds.includes(option.Id),
          nivel: '1',
        });

        if (!isOptionExpanded) return;

        option.MatrizCurricularPorCurso.forEach((child) => {
          result.push({
            id: child.Id,
            label: child.Descricao,
            hasChildren: false,
            isExpanded: false,
            isSelected: selectedChildrenRightIds.includes(child.Id),
            nivel: '2',
          });
        });
      });

    return result;
  }, [
    options,
    normalizeText,
    searchRight,
    expandedItemsRightIds,
    selectedItemsRightIds,
    selectedChildrenRightIds,
  ]);

  const isAllCheckedLeft = useMemo(() => {
    return (
      filterOptionsLeft.length > 0 &&
      filterOptionsLeft.every((option) => option.isSelected)
    );
  }, [filterOptionsLeft]);

  const isAllCheckedRight = useMemo(() => {
    return (
      filterOptionsRight.length > 0 &&
      filterOptionsRight.every((option) => option.isSelected)
    );
  }, [filterOptionsRight]);

  const handleCheckLeft = useCallback(
    (itemId: string, nivel: string) => {
      if (nivel === '1') {
        const childrenIds = options
          .find((option) => option.Id === itemId)
          ?.MatrizCurricularPorCurso.map((child) => child.Id);

        if (selectedItemsLeftIds.includes(itemId)) {
          setSelectedItemsLeftIds((old) => [
            ...old.filter((oldItemId) => oldItemId !== itemId),
          ]);

          setSelectedChildrenLeftIds((old) => [
            ...old.filter((oldItemId) => !childrenIds?.includes(oldItemId)),
          ]);
        } else {
          setSelectedItemsLeftIds((old) => [...old, itemId]);
          if (childrenIds) {
            setSelectedChildrenLeftIds((old) => [...old, ...childrenIds]);
          }
        }

        setExpandedItemsLeftIds((old) => [...old, itemId]);
      } else {
        setSelectedChildrenLeftIds((old) => {
          if (old.includes(itemId)) {
            return [...old.filter((oldItemId) => oldItemId !== itemId)];
          } else {
            return [...old, itemId];
          }
        });
      }
    },
    [options, selectedItemsLeftIds],
  );

  const handleCheckRight = useCallback(
    (itemId: string, nivel: string) => {
      if (nivel === '1') {
        const childrenIds = options
          .find((option) => option.Id === itemId)
          ?.MatrizCurricularPorCurso.map((child) => child.Id);

        if (selectedItemsRightIds.includes(itemId)) {
          setSelectedItemsRightIds((old) => [
            ...old.filter((oldItemId) => oldItemId !== itemId),
          ]);

          setSelectedChildrenRightIds((old) => [
            ...old.filter((oldItemId) => !childrenIds?.includes(oldItemId)),
          ]);
        } else {
          setSelectedItemsRightIds((old) => [...old, itemId]);
          if (childrenIds) {
            setSelectedChildrenRightIds((old) => [...old, ...childrenIds]);
          }
        }

        setExpandedItemsRightIds((old) => [...old, itemId]);
      } else {
        setSelectedChildrenRightIds((old) => {
          if (old.includes(itemId)) {
            return [...old.filter((oldItemId) => oldItemId !== itemId)];
          } else {
            return [...old, itemId];
          }
        });
      }
    },
    [options, selectedItemsRightIds],
  );

  const handleExpandedLeft = useCallback((itemId: string) => {
    setExpandedItemsLeftIds((old) => {
      if (old.includes(itemId)) {
        return old.filter((oldItemId) => oldItemId !== itemId);
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const handleExpandedRight = useCallback((itemId: string) => {
    setExpandedItemsRightIds((old) => {
      if (old.includes(itemId)) {
        return old.filter((oldItemId) => oldItemId !== itemId);
      } else {
        return [...old, itemId];
      }
    });
  }, []);

  const handleSearchLeft = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsInputLoadingLeft(true);

      setSearchLeft(e.target.value);
      debounce(() => {
        setIsInputLoadingLeft(false);
      });
    },
    [debounce],
  );

  const handleSearchRight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsInputLoadingRight(true);

      setSearchRight(e.target.value);
      debounce(() => {
        setIsInputLoadingRight(false);
      });
    },
    [debounce],
  );

  const handleCheckAllLeft = useCallback(() => {
    if (isAllCheckedLeft) {
      setSelectedItemsLeftIds([]);
      setSelectedChildrenLeftIds([]);
      return;
    }
    const optionsIds = options
      .filter((option) =>
        normalizeText(option.DescricaoCurso).includes(
          normalizeText(searchLeft),
        ),
      )
      .map((option) => option.Id);
    const childrenIds = options
      .flatMap((option) => option.MatrizCurricularPorCurso)
      .filter((child) =>
        normalizeText(child.Descricao).includes(normalizeText(searchLeft)),
      )
      .map((child) => child.Id);

    setSelectedItemsLeftIds([...optionsIds]);
    setSelectedChildrenLeftIds([...childrenIds]);
  }, [isAllCheckedLeft, normalizeText, options, searchLeft]);

  const handleCheckAllRight = useCallback(() => {
    if (isAllCheckedRight) {
      setSelectedItemsRightIds([]);
      setSelectedChildrenRightIds([]);
      return;
    }
    const optionsIds = options
      .filter((option) =>
        normalizeText(option.DescricaoCurso).includes(
          normalizeText(searchRight),
        ),
      )
      .map((option) => option.Id);
    const childrenIds = options
      .flatMap((option) => option.MatrizCurricularPorCurso)
      .filter((child) =>
        normalizeText(child.Descricao).includes(normalizeText(searchRight)),
      )
      .map((child) => child.Id);

    setSelectedItemsRightIds([...optionsIds]);
    setSelectedChildrenRightIds([...childrenIds]);
  }, [isAllCheckedRight, normalizeText, options, searchRight]);

  const handleTransferToleft = useCallback(() => {
    const optionsToTransfer: ICursosCurriculos[] = options.map((option) => {
      const isSelected = selectedItemsRightIds.includes(option.Id);

      const children = option.MatrizCurricularPorCurso.map((child) => {
        const isChildSelected = selectedChildrenRightIds.includes(child.Id);

        return {
          ...child,
          Selecionado: isChildSelected ? false : child.Selecionado,
        };
      });

      return {
        ...option,
        Selecionado: isSelected ? false : option.Selecionado,
        MatrizCurricularPorCurso: children,
      };
    });

    setOptions([...optionsToTransfer]);

    setExpandedItemsRightIds([]);
    setSelectedItemsRightIds([]);
    setSelectedChildrenRightIds([]);
  }, [options, selectedChildrenRightIds, selectedItemsRightIds]);

  const handleTransferToRight = useCallback(() => {
    const optionsToTransfer: ICursosCurriculos[] = options.map((option) => {
      const isSelected = selectedItemsLeftIds.includes(option.Id);

      const children = option.MatrizCurricularPorCurso.map((child) => {
        const isChildSelected = selectedChildrenLeftIds.includes(child.Id);

        return {
          ...child,
          Selecionado: isChildSelected ? true : child.Selecionado,
        };
      });

      return {
        ...option,
        Selecionado: isSelected ? true : option.Selecionado,
        MatrizCurricularPorCurso: children,
      };
    });

    setOptions([...optionsToTransfer]);

    setExpandedItemsLeftIds([]);
    setSelectedItemsLeftIds([]);
    setSelectedChildrenLeftIds([]);
  }, [options, selectedItemsLeftIds, selectedChildrenLeftIds]);

  const handleSaveAndNext = useCallback(() => {
    const cursos = options
      .filter(
        (option) =>
          option.Selecionado ||
          option.MatrizCurricularPorCurso.some((item) => item.Selecionado),
      )
      .map((option) => option.Id);

    const curriculos: string[] = [];
    options.map((option) =>
      option.MatrizCurricularPorCurso.filter((item) => item.Selecionado).map(
        (item) => curriculos.push(item.Id),
      ),
    );

    if (cursos.length || curriculos.length) {
      const cursosCurriculosPost: ICursosCurriculosPost = {
        IdPublicoAlvo: idPublicoAlvo,
        Chave: '',
        IdsValoresSelecionados: [],
        Filtros: [
          {Chave: 'cursos', IdsValoresSelecionados: cursos},
          {Chave: 'curriculos', IdsValoresSelecionados: curriculos},
        ],
      };

      CursosCurriculosService.post(cursosCurriculosPost).then((response) => {
        if (isPublicoSemDisciplinas && response.Success) {
          setOpenDialog(true);
        }
        if (response.Success && !isPublicoSemDisciplinas) {
          nextStep();
        } else {
          feedback(response.Message, 'success');
        }
      });
    } else {
      feedback('Você deve selecionar ao menos um item.', 'error');
    }
  }, [idPublicoAlvo, nextStep, options, isPublicoSemDisciplinas]);

  const handleStartBackgroundProcess = useCallback(
    (idPublicoAlvo: string) => {
      setIsLoading(true);
      PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then(() => {
        setIsLoading(false);
        navigate('/publico-alvo');
      });
    },
    [navigate],
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Cursos e currículos</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por curso"
                value={searchLeft}
                onChange={handleSearchLeft}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoadingLeft || isInputLoadingLeft ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid container item xs={5} alignItems="flex-end">
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por curso"
                value={searchRight}
                onChange={handleSearchRight}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoadingRight || isInputLoadingRight ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          {isLoadingLeft ? (
            <TransferListSkeleton />
          ) : options.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={filterOptionsLeft}
              isCheckedAll={isAllCheckedLeft}
              onCheckedAll={handleCheckAllLeft}
              onCheckedItem={handleCheckLeft}
              onExpandedItem={handleExpandedLeft}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Box
              width="100%"
              paddingX={2}
              display={'flex'}
              flexDirection={'column'}
              gap={1}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleTransferToRight}
                disabled={options.length === 0}>
                <Icon>double_arrow_outlined</Icon>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleTransferToleft}
                disabled={options.length === 0}>
                <Icon style={{transform: 'rotate(180deg)'}}>
                  double_arrow_outlined
                </Icon>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {isLoadingRight ? (
            <TransferListSkeleton />
          ) : options.length ? (
            <VirtualizedTransferList
              title="MARCAR/DESMARCAR TODOS"
              items={filterOptionsRight}
              isCheckedAll={isAllCheckedRight}
              onCheckedAll={handleCheckAllRight}
              onCheckedItem={handleCheckRight}
              onExpandedItem={handleExpandedRight}
            />
          ) : (
            <Card style={{width: '100%'}}>
              <NotFoundText text="Nenhum registro encontrado para os parâmetros informados" />
            </Card>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        aria-labelledby="modal-detail">
        <DialogTitle>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyItems={'space-between'}>
            <Typography color="primary" variant="h6">
              Filtros salvos com sucesso!
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1">
            Estamos realizando a busca das pessoas. Agora, você será
            redirecionado para a página inicial do Público-alvo. Enviaremos uma
            notificação ao concluir esse processo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleStartBackgroundProcess(idPublicoAlvo);
            }}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ActionTab
        onSaveLabel="Avançar"
        onCancelLabel="Voltar"
        onCancel={previousStep}
        onSave={
          isLoadingLeft && isLoadingRight ? () => false : handleSaveAndNext
        }
      />
    </>
  );
};
