import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTitle} from '../../shared/hooks';
import {
  Container,
  Grid,
  InputLabel,
  OutlinedInput,
  FormControl,
  TextField,
} from '@mui/material';
import {Autocomplete} from '@mui/lab';
import {
  GrupoPapeisService,
  IPapeisERP,
} from 'shared/services/api/grupo-papeis/GrupoPapeisList';
import {
  GrupoPapeisDetailService,
  IGrupoPapeisDetail,
} from 'shared/services/api/grupo-papeis/GrupoPapeisDetail';
import {feedback} from 'shared/services';
import {ActionTab} from 'shared/components/action-tab/ActionTab';

export const GrupoPapeisDetail: React.FC = () => {
  const {id = '0'} = useParams<'id'>();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const [nomeGrupo, setNomeGrupo] = useState('');
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<IPapeisERP[]>(
    [],
  );
  const [autoCompleteValue, setAutoCompleteValue] = useState<IPapeisERP[]>([]);

  useEffect(() => {
    GrupoPapeisService.getAllPapeisErp().then((data) => {
      if (data.Success) {
        setAutoCompleteOptions(data.Data);
      } else {
        feedback(data.Message || 'Erro ao carregar grupo de papéis.', 'error');
      }
    });
  }, []);

  useEffect(() => {
    GrupoPapeisService.getById(id).then((data) => {
      if (data.Success) {
        setNomeGrupo(data.Data.Nome);
        setAutoCompleteValue(data.Data.PapeisErp);
        setTitle(`Grupo de papéis: ${data.Data.Nome}`);
      } else {
        feedback(data.Message || 'Erro ao buscar grupo.', 'error');
      }
    });
  }, [id, setTitle]);

  const handleSubmit = useCallback(
    (formData: IPapeisERP[]) => {
      if (!formData.length) {
        feedback('Campos inválidos!', 'error');
        return;
      }

      feedback('Salvando...', 'loading');

      let GrupoPapeis: IGrupoPapeisDetail = {
        Id: id,
        PapeisERP: formData.map((item) => item.Id),
      };

      GrupoPapeisDetailService.put(GrupoPapeis).then((data) => {
        if (data.Success) {
          feedback('Grupo de papéis salvo com sucesso!', 'success');
        } else {
          feedback(data.Message || 'Erro ao salvar grupo de papéis.', 'error');
        }
      });
    },
    [id],
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid container item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="grupo">Grupo</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                value={nomeGrupo || ''}
                labelWidth={45}
                disabled
              />
            </FormControl>
          </Grid>

          <Grid container item xs={12} spacing={0}>
            <Grid item xs={12}>
              <FormControl margin="none" fullWidth>
                <Autocomplete
                  fullWidth
                  multiple
                  id="papeis-erp"
                  options={autoCompleteOptions}
                  getOptionLabel={(option) => option.Papel}
                  value={autoCompleteValue}
                  isOptionEqualToValue={(option, value) =>
                    option.Id === value.Id
                  }
                  onChange={(event, value) => {
                    setAutoCompleteValue(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Papéis ERP"
                      placeholder="Papéis ERP"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ActionTab
        onSave={() => {
          handleSubmit(autoCompleteValue);
        }}
        onCancel={() => {
          navigate('/grupo-de-papeis');
        }}
        onCancelLabel={'Voltar'}
      />
    </Container>
  );
};
