import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {
  Box,
  Grid,
  Table,
  Theme,
  Paper,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  FormControl,
  InputAdornment,
  TableContainer,
  TextField,
  Chip,
  CircularProgress,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import {Pagination, Skeleton} from '@mui/lab';
import {ActionTab, NotFoundText} from 'shared/components';
import {feedback} from 'shared/services';
import {Environment} from 'shared/Env';
import {
  IPessoas,
  IPessoasPost,
  PessoasService,
  IPessoaPostStruct,
  ISituacaoCurso,
} from 'shared/services/api/pessoas/Pessoas';
import {useUser} from 'shared/hooks';
import {Autocomplete} from '@mui/lab';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  paginationNav: {
    padding: '5px',
  },
  listItem: {
    borderBottom: '4px solid #fafafa',
  },
}));

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  firstStep: () => void;
  finish: () => void;
  isRecargaAutomatica: boolean;
  hasNecessaryData: boolean;
}

interface HeadCell {
  id: keyof IPessoas;
  label: string;
  width?: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = (props) => {
  const {onSelectAllClick, numSelected, rowCount} = props;
  const {isCollege} = useUser();

  var headCells: HeadCell[] = [
    {
      id: 'Nome',
      label: 'Nome',
    },
    {
      id: 'Papel',
      label: 'Papel',
      width: 120,
    },
    {
      id: 'DescricaoCurso',
      label: 'Curso',
    },
  ];

  if (!isCollege) {
    headCells.push({
      id: 'SituacaoAlunoCurso',
      label: 'Situação no curso',
    });
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'selecionar tudo'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.width || 'auto'}
            align="left">
            <Typography>
              <strong>{headCell.label}</strong>
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const Pessoas: React.FC<IStepProps> = ({
  idPublicoAlvo,
  previousStep,
  firstStep,
  finish,
  isRecargaAutomatica,
  hasNecessaryData,
}) => {
  const {isCollege} = useUser();
  const classes = useStyles();
  const rowsPerPage = Environment.LIMITE_DE_LINHAS;
  const [pessoas, setPessoas] = useState<IPessoas[]>([]);
  const [pessoasCopy, setPessoasCopy] = useState<IPessoas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<IPessoaPostStruct[]>([]);
  const [notSelected, setNotSelected] = useState<IPessoaPostStruct[]>([]);
  const [statusOnCourse, setStatusOnCourse] = useState<ISituacaoCurso[]>([]);
  const [statusOptions, setStatusOptions] = useState<ISituacaoCurso[]>([]);
  const location = useLocation();

  const goToLastStep = useMemo(() => {
    const location = window.location.href;
    const params = new URL(location).searchParams;
    return params.get('lastStep') ? true : false;
  }, []);

  useEffect(() => {
    const result: IPessoaPostStruct[] = [];

    PessoasService.getById(idPublicoAlvo).then((response) => {
      if (response.Success) {
        setPessoas(response.Data);
        setPessoasCopy(response.Data);
        response.Data.filter((pessoa) => pessoa.Selecionado).forEach((item) => {
          result.push({
            IdPessoa: item.Codigo,
            IdGrupoPapel: item.IdGrupoPapel,
            CodigoPessoaGrupoPapel: item.CodigoPessoaGrupoPapel,
            CodigoUsuarioAuthDoFilhoAluno: item.CodigoUsuarioAuthDoFilhoAluno,
          });
        });
        setSelected(result);
        setIsLoading(false);
      } else {
        feedback(response.Message, 'error');
      }
    });

    PessoasService.getSituacaoCursoById(idPublicoAlvo).then((response) => {
      if (response.Success) {
        setStatusOnCourse(
          response.Data.filter(
            (status) =>
              status.Selecionado && status.Id !== null && status.Id !== '',
          ),
        );
        setStatusOptions(
          response.Data.filter(
            (status) => status.Id !== null && status.Id !== '',
          ),
        );
      } else {
        feedback(
          response.Message || 'Erro ao carregar situações de curso.',
          'error',
        );
      }
    });
  }, [idPublicoAlvo, location]);

  useEffect(() => {
    const resultNotSelected = pessoas.filter(
      (pessoa) =>
        !selected.some(
          (selectedItem) =>
            selectedItem.CodigoPessoaGrupoPapel ===
            pessoa.CodigoPessoaGrupoPapel,
        ),
    );
    const notSelectedItems: IPessoaPostStruct[] = [];
    resultNotSelected.forEach((item) => {
      notSelectedItems.push({
        IdPessoa: item.Codigo,
        IdGrupoPapel: item.IdGrupoPapel,
        CodigoPessoaGrupoPapel: item.CodigoPessoaGrupoPapel,
        CodigoUsuarioAuthDoFilhoAluno: item.CodigoUsuarioAuthDoFilhoAluno,
      });
    });
    setNotSelected(notSelectedItems);
  }, [selected, pessoas]);

  const normalizeText = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const pessoasMemo = useMemo(() => {
    const result = pessoasCopy.filter(
      (pessoa) =>
        pessoa &&
        (normalizeText(pessoa.Nome).includes(normalizeText(search)) ||
          normalizeText(pessoa.Papel).includes(normalizeText(search))),
    );

    if (statusOnCourse.length > 0) {
      return result.filter((pessoa) => {
        return statusOnCourse.some((status) => {
          return status.Id === pessoa.SituacaoAlunoCurso;
        });
      });
    }

    return result;
  }, [pessoasCopy, statusOnCourse, search, normalizeText]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelecteds: IPessoaPostStruct[] = [];
        pessoas.forEach((pessoa) => {
          newSelecteds.push({
            IdPessoa: pessoa.Codigo,
            IdGrupoPapel: pessoa.IdGrupoPapel,
            CodigoPessoaGrupoPapel: pessoa.CodigoPessoaGrupoPapel,
            CodigoUsuarioAuthDoFilhoAluno: pessoa.CodigoUsuarioAuthDoFilhoAluno,
          });
        });
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [pessoas],
  );

  const handleClick = useCallback(
    (pessoa: IPessoaPostStruct) => {
      const selectedIndex = selected
        .map((slctItem) => {
          return slctItem.CodigoPessoaGrupoPapel;
        })
        .indexOf(pessoa.CodigoPessoaGrupoPapel);
      let newSelected: IPessoaPostStruct[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, pessoa);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [selected],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const isSelected = useCallback(
    (pessoa: IPessoaPostStruct) => {
      return selected.some(
        (selectedItem) =>
          selectedItem.CodigoPessoaGrupoPapel === pessoa.CodigoPessoaGrupoPapel,
      )
        ? true
        : false;
    },
    [selected],
  );

  const handleSaveAndNext = useCallback(() => {
    feedback('Salvando público-alvo...', 'loading');
    setIsLoading(true);
    let pessoasPost: IPessoasPost = {
      IdPublicoAlvo: idPublicoAlvo,
      RecargaAutomatica: isRecargaAutomatica,
      Pessoas: notSelected,
    };

    PessoasService.postSituacao(statusOnCourse, idPublicoAlvo).then(
      (response) => {
        if (!response.Success) {
          feedback('Erro ao salvar os filtros de situação do curso.', 'error');
        }
      },
    );

    if (!isRecargaAutomatica) {
      if (selected.length === 0) {
        feedback('Selecione pessoas para o Público-alvo.', 'error');
        return;
      }

      pessoasPost.Pessoas = [...selected];
    }

    PessoasService.enviarPessoas(pessoasPost).then((data) => {
      if (data.Success) {
        feedback('Você salvou o Público-alvo', 'success');
        finish();
      } else {
        feedback(data.Message || 'Erro ao finalizar público-alvo.', 'error');
      }
      setIsLoading(false);
    });
  }, [
    selected,
    isRecargaAutomatica,
    idPublicoAlvo,
    notSelected,
    finish,
    statusOnCourse,
  ]);

  return (
    <>
      <Grid container item xs={12} justifyItems={'space-between'}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Pessoas</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        width={'100%'}
        display={'flex'}
        justifyItems={'space-between'}
        alignItems={'end'}
        gap={12}>
        <Box width={!isCollege ? '100%' : '40%'}>
          <FormControl fullWidth variant="outlined">
            <Paper>
              <TextField
                style={{padding: 0, margin: 0}}
                fullWidth
                variant="outlined"
                placeholder="Pesquisar por pessoas"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <CircularProgress size={30} />
                      ) : (
                        <SearchIcon color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </FormControl>
        </Box>
        {isCollege === false && (
          <Box width={'100%'}>
            <Box width={'100%'}>
              <Box width={'100%'}>
                <Paper>
                  <FormControl fullWidth>
                    <Autocomplete
                      disableListWrap
                      fullWidth
                      multiple
                      limitTags={3}
                      loading={isLoading}
                      id="situacao-no-curso"
                      options={statusOptions}
                      getOptionLabel={(option) => option.SituacaoNoCurso}
                      value={statusOnCourse}
                      isOptionEqualToValue={(option, value) =>
                        option.Id === value.Id
                      }
                      onChange={(event, value) => {
                        setStatusOnCourse(value);
                        setPage(1);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            size="small"
                            color="primary"
                            label={option.SituacaoNoCurso}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Situação no curso"
                          placeholder="Selecione"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Paper>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box marginBottom={2} />
      <Box>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={pessoas.length}
                />
                <TableBody>
                  {isLoading ? (
                    <>
                      <TableRow hover>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        {isCollege === false && (
                          <TableCell component="th" scope="row">
                            <Skeleton
                              variant="rectangular"
                              width="auto"
                              height={25}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow hover>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="auto"
                            height={25}
                          />
                        </TableCell>
                        {isCollege === false && (
                          <TableCell component="th" scope="row">
                            <Skeleton
                              variant="rectangular"
                              width="auto"
                              height={25}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  ) : pessoasMemo.length ? (
                    pessoasMemo
                      .slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                      )
                      .map((pessoa, index) => {
                        let pessoaStruct: IPessoaPostStruct = {
                          IdPessoa: pessoa.Codigo,
                          IdGrupoPapel: pessoa.IdGrupoPapel,
                          CodigoPessoaGrupoPapel: pessoa.CodigoPessoaGrupoPapel,
                          CodigoUsuarioAuthDoFilhoAluno:
                            pessoa.CodigoUsuarioAuthDoFilhoAluno,
                        };
                        const isItemSelected = isSelected(pessoaStruct);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            className={classes.listItem}
                            hover
                            onClick={() => handleClick(pessoaStruct)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={`pessoa-${pessoa.CodigoPessoaGrupoPapel}`}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{'aria-labelledby': labelId}}
                              />
                            </TableCell>
                            <TableCell align="left" id={labelId} scope="row">
                              {pessoa.Nome}
                            </TableCell>
                            <TableCell align="left" width={120}>
                              {pessoa.Papel}
                            </TableCell>
                            <TableCell align="left">
                              {pessoa.DescricaoCurso}
                            </TableCell>
                            {isCollege === false && (
                              <TableCell align="left">
                                {pessoa.SituacaoAlunoCurso}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell padding={'none'} scope="row" colSpan={5}>
                        <NotFoundText />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              marginTop={1}
              marginBottom={1}
              marginLeft={1}
              marginRight={1}
              display={'flex'}
              justifyItems={'end'}>
              <Pagination
                className={classes.paginationNav}
                page={page}
                count={Math.ceil(pessoasMemo.length / rowsPerPage)}
                onChange={handleChangePage}
              />
            </Box>
          </Paper>
        </div>
      </Box>
      {!isLoading && (
        <ActionTab
          onSaveLabel="Concluir"
          onCancelLabel="Voltar"
          onCancel={
            goToLastStep
              ? undefined
              : hasNecessaryData
              ? previousStep
              : firstStep
          }
          onSave={handleSaveAndNext}
        />
      )}
    </>
  );
};
