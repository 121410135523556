import {Step, Stepper as MuiStepper, StepLabel, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

interface IStepperProps {
  steps: string[];
  activeStep: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const Stepper: React.FC<IStepperProps> = ({steps, activeStep}) => {
  const styles = useStyles();

  return (
    <MuiStepper activeStep={activeStep} className={styles.stepper}>
      {steps.map((step) => {
        const stepProps: {completed?: boolean} = {};
        const labelProps: {optional?: React.ReactNode} = {};

        return (
          <Step key={step} {...stepProps}>
            <StepLabel {...labelProps}>{step}</StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};
