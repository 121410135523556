import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
  Typography,
  Tooltip,
} from '@mui/material';
import RestartProcessIcon from '@mui/icons-material/Refresh';
import {Pagination, Skeleton} from '@mui/lab';
import {Environment} from 'shared/Env';
import {
  IPublicoHistory,
  PublicoAlvoListService,
} from 'shared/services/api/publico-alvo/PublicoAlvoList';
import {feedback} from 'shared/services';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';
import {format} from 'date-fns';

interface IHistoryProps {
  idPublicoAlvo: string;
  nomePublico: string;
  setIsLoadingList: (isLoading: boolean) => void;
}

export const History: React.FC<IHistoryProps> = ({
  idPublicoAlvo,
  nomePublico,
  setIsLoadingList,
}) => {
  const [history, setHistory] = useState<IPublicoHistory[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const rowsPerPage = useMemo(() => {
    return Environment.LIMITE_DE_LINHAS;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    PublicoAlvoListService.getHistoryById(idPublicoAlvo).then((result) => {
      if (result.Success) {
        setHistory(result.Data);
      } else {
        feedback(
          result.Message || 'Erro ao carregar histórico do público-alvo.',
          'error',
        );
      }
      setIsLoading(false);
    });
  }, [idPublicoAlvo]);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleStartBackgroundProcess = useCallback(
    (idPublicoAlvo: string) => {
      setIsLoading(true);
      setIsLoadingList(true);
      PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then(
        (result) => {
          if (!result.Success) {
            feedback(
              result.Message ||
                'Falha ao reinicar processamento do público-alvo.',
              'error',
            );
          }
          setIsLoading(false);
          setIsLoadingList(false);
        },
      );
    },
    [setIsLoadingList],
  );

  return (
    <Box width={'1100px'} margin={'24px auto'}>
      <Box width={'100%'} marginBottom={2} display={'flex'} gap={0.5}>
        <Typography>
          <strong>Nome: </strong>
        </Typography>
        <Typography variant="body1" color="primary">
          {nomePublico || ''}
        </Typography>
      </Box>
      <Grid container>
        <Grid container item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Etapa de Processamento
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Data e hora
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Mensagem de erro
                  </TableCell>
                  <TableCell component="th" scope="row" width={65}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {history.length > 0 ? (
                      <>
                        {history
                          .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                          )
                          .map((hist) => (
                            <TableRow
                              hover
                              key={`status-processamento-${hist.Id}`}>
                              <TableCell component="th" scope="row">
                                {hist.EtapaDeProcessamento}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {hist.Status}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {`${format(
                                  new Date(hist.DataHora),
                                  'dd/MM/yyyy HH:mm',
                                )}`}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {hist.MensagemDeErro}
                              </TableCell>
                              <TableCell component="th" scope="row" width={65}>
                                {hist.MensagemDeErro ? (
                                  <Tooltip title="Reiniciar processamento do público-alvo.">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        handleStartBackgroundProcess(
                                          hist.IdPublicoAlvo,
                                        );
                                      }}>
                                      <RestartProcessIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={4}>
                          <Typography variant={'body1'} color="primary">
                            Não existe nenhum histórico para este público-alvo.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {history.length > rowsPerPage && (
              <Box
                marginTop={1}
                marginBottom={1}
                marginLeft={1}
                marginRight={1}>
                <Pagination
                  page={page}
                  count={Math.ceil(history.length / rowsPerPage)}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
