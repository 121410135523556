import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IPessoas {
  Codigo: number;
  Nome: string;
  Email?: string;
  DescricaoCurso: string;
  Papel: string;
  IdGrupoPapel?: string;
  SituacaoAlunoCurso?: string;
  CodigoPessoaGrupoPapel: string;
  Selecionado: boolean;
  CodigoUsuarioAuthDoFilhoAluno?: string;
}

export interface IPessoaPostStruct {
  IdPessoa: number;
  IdGrupoPapel?: string;
  CodigoPessoaGrupoPapel: string;
  CodigoUsuarioAuthDoFilhoAluno?: string;
}

export interface IPessoasPost {
  IdPublicoAlvo: string;
  RecargaAutomatica: boolean;
  Pessoas?: IPessoaPostStruct[];
}

export interface ISituacaoCurso {
  Id: string;
  SituacaoNoCurso: string;
  Selecionado: boolean;
}

const getById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<IPessoas[]>> => {
  try {
    const {data} = await Api.get(`Pessoas/${idPublicoAlvo}`);

    const response: IResultOfAction<IPessoas[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const enviarPessoas = async (
  pessoas: IPessoasPost,
): Promise<IResultOfAction<IPessoasPost>> => {
  try {
    const {data} = await Api.post(`Pessoas`, pessoas);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const postSituacao = async (
  situacao: ISituacaoCurso[],
  idPublicoAlvo: string,
): Promise<IResultOfAction<ISituacaoCurso>> => {
  try {
    const postObject = {
      Chave: 'situacoesalunonocurso',
      IdsValoresSelecionados: situacao.map((status) => status.Id),
      IdPublicoAlvo: idPublicoAlvo,
    };

    const {data} = await Api.post(`SituacoesAlunoNoCurso`, postObject);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getSituacaoCursoById = async (
  idPublicoAlvo: string,
): Promise<IResultOfAction<ISituacaoCurso[]>> => {
  try {
    const {data} = await Api.get(`SituacoesAlunoNoCurso/${idPublicoAlvo}`);

    const response: IResultOfAction<ISituacaoCurso[]> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PessoasService = {
  getById,
  enviarPessoas,
  getSituacaoCursoById,
  postSituacao,
};
