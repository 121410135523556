import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IUsuarios {
  IdUsuario: string;
  Login: string;
  Nome: string;
  Ativo: boolean;
  CodigoUsuario: number;
}

export interface IUsuarioById {
  CodigoUsuario: number;
  Ativo: boolean;
  Estabelecimentos: number[];
}

export interface IEstablishmentTree {
  IdEstabelecimento: number;
  NomeEstabelecimento: string;
  Sigla: string;
  CodigoEstruturadoInt: number;
  CodigoPai: string;
  EstabelecimentosFilhos: IEstablishmentTree[];
}

export interface IPermissions {
  CodigoUsuario: number;
  CodigoUsuarioAuth: number;
  Ativo: boolean;
  Estabelecimentos: number[];
}

const getUsuarios = async (): Promise<IResultOfAction<IUsuarios[]>> => {
  try {
    const {data} = await Api.get('permissoes/Usuarios');

    const response: IResultOfAction<any> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getListaEmArvore = async (): Promise<
  IResultOfAction<IEstablishmentTree[]>
> => {
  try {
    const {data} = await Api.get(`Estabelecimentos/listaemarvore`);

    const response: IResultOfAction<any> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getUsuariosById = async (id: string): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.get(`permissoes/Usuario/${id}`);

    const response: IResultOfAction<any> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (
  postObject: IPermissions,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.post('/permissoes/usuario', postObject);

    // const response: IResultOfAction<any> = {
    //   data: data.Data,
    //   success: data.Success,
    //   message: data.Message,
    // };

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const putPermissoes = async (
  id: any,
  postObject: any,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.put(`/permissoes/usuario/${id}`, postObject);

    // const response: IResultOfAction<any> = {
    //   data: data.Data,
    //   success: data.Success,
    //   message: data.Message,
    // };

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const UsuariosService = {
  getUsuarios,
  getListaEmArvore,
  getUsuariosById,
  create,
  putPermissoes,
};
