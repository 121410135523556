import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {EstabSelectionTreeSkeleton} from 'pages/publico-alvo/components/establishment/EstabSelectionTreeSkeleton';
import React, {useEffect, useState} from 'react';
import {feedback} from 'shared/services/alert';
import {
  IEstablishmentTree,
  UsuariosService,
} from 'shared/services/api/usuarios/Usuarios';
import {EstabSelectionTree} from '../estabalecimento/EstabSelectionTree';
import {ActionTab} from 'shared/components';
import PersonIcon from '@mui/icons-material/Person';
import {useNavigate, useParams} from 'react-router-dom';

export const AdicionarPermissoes: React.FC = () => {
  const {name, id = '0'} = useParams<'id' | 'name'>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';

  const [listadDeUsuarios, setListaDeUsuarios] = useState<IEstablishmentTree[]>(
    [],
  );

  const [usuario, setUsuario] = useState<any>([]);
  const [userSelectedEstablishments, setUserSelectedEstablishments] = useState<
    number[]
  >([]);
  const [userNewSelectedEstablishments, setUserNewSelectedEstablishments] =
    useState<number[]>([]);

  useEffect(() => {
    UsuariosService.getListaEmArvore().then((data) => {
      if (data.Success) {
        setListaDeUsuarios(data.Data);
      } else {
        feedback(data.Message || 'Erro ao listar os usuários.', 'error');
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    UsuariosService.getUsuariosById(id).then((data) => {
      if (data.Success) {
        setUsuario(data.Data);
        setUserSelectedEstablishments(data.Data.Estabelecimentos);
      } else {
        feedback(data.Message || 'Erro ao listar os usuários.', 'error');
      }
    });
  }, [id]);

  useEffect(() => {
    if (
      userSelectedEstablishments.length > 0 &&
      userNewSelectedEstablishments.length === 0 &&
      usuario.Id !== DEFAULT_ID
    ) {
      setUserNewSelectedEstablishments([...userSelectedEstablishments]);
    }
  }, [usuario, userNewSelectedEstablishments, userSelectedEstablishments]);

  const savePermissions = async () => {
    if (userNewSelectedEstablishments.length > 0) {
      feedback('Salvando permissões...', 'loading');
      if (usuario.Id === DEFAULT_ID) {
        const {Success, Message} = await UsuariosService.create({
          CodigoUsuario: Number(id),
          CodigoUsuarioAuth: usuario.CodigoUsuarioAuth,
          Ativo: true,
          Estabelecimentos: userNewSelectedEstablishments,
        });
        if (Success) {
          feedback('Permissões salvas com sucesso!', 'success');
          navigate(`/usuarios`);
        } else {
          feedback(
            Message || 'Erro ao criar permissões para o usuário.',
            'error',
          );
        }
      } else {
        const {Success, Message} = await UsuariosService.putPermissoes(id, {
          CodigoUsuario: Number(id),
          Id: usuario.Id,
          CodigoUsuarioAuth: usuario.CodigoUsuarioAuth,
          Ativo: true,
          Estabelecimentos: userNewSelectedEstablishments,
        });
        if (Success) {
          feedback('Permissões salvas com sucesso!', 'success');
          navigate(`/usuarios`);
        } else {
          feedback(
            Message || 'Erro ao criar permissões para o usuário.',
            'error',
          );
        }
      }
    } else {
      feedback(
        'Selecione ao menos 1 estabelecimento para o Público-alvo',
        'error',
      );
    }
  };

  return (
    <Container>
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <Paper>
            <TextField
              style={{padding: 0, margin: 0}}
              fullWidth
              variant="outlined"
              placeholder="Pesquise pelo nome do perfil"
              disabled
              value={isLoading ? 'Nome' : name}
              InputProps={{
                style: {
                  height: 40,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </FormControl>
      </Grid>

      <Box marginTop={4} marginLeft={1}>
        <Box width="100%">
          <Typography variant="subtitle1">
            <strong>Estabelecimentos</strong>
          </Typography>
        </Box>
        {isLoading ? (
          <EstabSelectionTreeSkeleton />
        ) : (
          <Box marginTop={2}>
            <EstabSelectionTree
              selectedEstablishments={userSelectedEstablishments}
              establishments={listadDeUsuarios}
              onChangeEstabs={(selectedEstabs) => {
                setUserNewSelectedEstablishments(selectedEstabs);
              }}
            />
          </Box>
        )}
      </Box>
      <ActionTab
        onCancel={() => {
          navigate('/usuarios');
        }}
        onSave={() => {
          savePermissions();
        }}
        onSaveLabel="Salvar"
      />
    </Container>
  );
};
