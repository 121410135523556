import React, {createContext, useEffect, useState} from 'react';
import {feedback} from 'shared/services';
import {ErrorPage} from 'shared/components';
import {IUser, UserService} from 'shared/services/api/user/User';

interface IUserContextData {
  isLogged: boolean;
  isCollege: boolean;
  user: IUser;
}

export const UserContext = createContext<IUserContextData>(
  {} as IUserContextData,
);

export const UserProvider: React.FC = ({children}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isCollege, setIsCollege] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseHeaders, setResponseHeaders] = useState<any>({} as any);
  const [user, setUser] = useState<IUser>({} as IUser);

  useEffect(() => {
    UserService.checkSession().then((result) => {
      if (result.Success) {
        setIsLogged(true);
        setResponseHeaders(result.Data);

        setUser({
          name: 'Gvdasa',
          forgotPasswordLink: 'https://gvdasa.com.br',
        });
      } else {
        if (result.Message) {
          if (result.Message.includes('ServiceUnavailable')) {
            setHasError(true);
            setErrorMessage(
              result.Message.substring(result.Message.indexOf('|') + 1),
            );
          }
        }
        feedback('Erro ao fazer login. Tente novamente mais tarde', 'error');
      }
    });

    if (responseHeaders.erpintegracao !== 'GVcentris') {
      setIsCollege(true);
    } else {
      setIsCollege(false);
    }
  }, [responseHeaders.erpintegracao]);

  return (
    <UserContext.Provider
      value={{isLogged: isLogged, isCollege: isCollege, user: user}}>
      {hasError ? <ErrorPage message={errorMessage} /> : children}
    </UserContext.Provider>
  );
};
