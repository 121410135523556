import {useState} from 'react';

export const useFeedback = () => {
  const [openModalFeedBack, setOpenModalFeedBack] = useState(false);

  const handleToggleModalFeedBack = () => {
    setOpenModalFeedBack((state) => !state);
  };

  return {openModalFeedBack, handleToggleModalFeedBack};
};
