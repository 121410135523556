import React from 'react';
import {Box, Theme, Typography, Icon} from '@mui/material';
import {GVSplashLogo} from 'shared/assets';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    shadow: {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '300px',
        height: '300px',
        backgroundColor: theme.palette.grey[500],
        opacity: 0.1,
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
      },
    },
    logo: {
      position: 'relative',
      width: '150px',
    },
  };
});

interface IErrorPageProps {
  message: string;
}

export const ErrorPage: React.FC<IErrorPageProps> = ({message}) => {
  const classes = useStyles();

  return (
    <Box minHeight={'100vh'} position={'relative'}>
      <Box
        position={'absolute'}
        width={'100%'}
        height={'100%'}
        display={'flex'}
        gap={20}
        justifyItems={'center'}
        alignItems={'center'}>
        <Box fontSize={0} position={'relative'} className={classes.shadow}>
          <img
            alt="Logo GVdasa"
            src={GVSplashLogo.default}
            className={classes.logo}
          />
        </Box>
        <Box width={'100%'} maxWidth={'600px'}>
          <Box
            display={'flex'}
            justifyItems={'flex-start'}
            alignItems={'center'}
            gap={5}>
            <Icon fontSize="large" color={'primary'}>
              warning
            </Icon>
            <Typography variant={'h4'} color={'primary'}>
              Sistema indisponível :(
            </Typography>
          </Box>
          <Typography color={'textSecondary'}>{message}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
