import {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Checkbox,
  FormGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {ActionTab} from 'shared/components';
import {
  INivelEnsino,
  INivelEnsinoPost,
  NiveisEnsinoService,
} from 'shared/services/api/niveis-ensino/NiveisEnsino';
import {feedback} from 'shared/services';
import {useLocation} from 'react-router-dom';

interface IStepProps {
  idPublicoAlvo: string;
  previousStep: () => void;
  nextStep: () => void;
}

export const Niveis: React.FC<IStepProps> = ({
  idPublicoAlvo,
  previousStep,
  nextStep,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [niveisEnsino, setNiveisEnsino] = useState<INivelEnsino[]>([]);
  const location = useLocation();

  useEffect(() => {
    NiveisEnsinoService.getById(idPublicoAlvo).then((data) => {
      if (data.Success) {
        setNiveisEnsino(data.Data);
      } else {
        feedback(data.Message || 'Erro ao carregar níveis de ensino.', 'error');
      }
      setIsLoading(false);
    });
  }, [idPublicoAlvo, location]);

  const handleSelect = useCallback(
    (position: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const niveisCopy = niveisEnsino;
      niveisCopy[position].Selecionado = e.target.checked;

      setNiveisEnsino([...niveisCopy]);
    },
    [niveisEnsino],
  );

  const handleSaveAndNext = useCallback(() => {
    const niveisEnsinoSelected = niveisEnsino
      .filter((nivelEnsino) => nivelEnsino.Selecionado)
      .map((nivel) => nivel.Id);

    const niveisPost: INivelEnsinoPost = {
      Chave: 'NiveisEnsino',
      IdPublicoAlvo: idPublicoAlvo,
      IdsValoresSelecionados: niveisEnsinoSelected,
    };

    NiveisEnsinoService.post(niveisPost).then((data) => {
      if (data.Success) {
        nextStep();
      } else {
        feedback(data.Message, 'error');
      }
    });
  }, [idPublicoAlvo, nextStep, niveisEnsino]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Níveis</strong>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
              <Box marginBottom={1}>
                <Skeleton variant="text" width="25%" height={35} />
              </Box>
            </>
          ) : (
            <FormControl component="fieldset">
              <FormGroup>
                {niveisEnsino.map((nivelEnsino, index) => {
                  return (
                    <FormControlLabel
                      key={nivelEnsino.Id}
                      control={
                        <Checkbox
                          color="primary"
                          checked={nivelEnsino.Selecionado}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSelect(index, e)
                          }
                          name={nivelEnsino.DescricaoNivel}
                        />
                      }
                      label={nivelEnsino.DescricaoNivel}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>

      <ActionTab
        onSaveLabel="Avançar"
        onCancelLabel="Voltar"
        onCancel={previousStep}
        onSave={isLoading ? () => false : handleSaveAndNext}
      />
    </>
  );
};
