import React, {useEffect, useState} from 'react';
import {Box, Typography, Checkbox} from '@mui/material';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {ExpandMore, ChevronRight} from '@mui/icons-material';
import {IEstablishmentTree} from 'shared/services/api/usuarios/Usuarios';

interface IEstabTreeSelectionProps {
  establishments: IEstablishmentTree[];
  onChangeEstabs: (establishments: number[]) => void;
  selectedEstablishments: any[];
}

export const EstabSelectionTree: React.FC<IEstabTreeSelectionProps> = ({
  establishments,
  onChangeEstabs,
  selectedEstablishments,
}) => {
  const [estabsFromDatabase, setEstabsFromDatabase] = useState<
    IEstablishmentTree[]
  >([]);
  const [estabsFromUserChoice, setEstabsFromUserChoice] = useState<number[]>(
    [],
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: string[]) => {
    setSelected(nodeIds);
  };
  useEffect(() => {
    if (selectedEstablishments.length > 0) {
      setEstabsFromUserChoice([...selectedEstablishments]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpanded(selectedEstablishments);
  }, [selectedEstablishments]);

  function getChildById(
    estabelecimentos: IEstablishmentTree,
    id: number,
  ): number[] {
    let array: number[] = [];

    function getAllChild(estabelecimentos: IEstablishmentTree | null) {
      if (estabelecimentos === null) return [];
      array.push(estabelecimentos?.IdEstabelecimento);
      if (Array.isArray(estabelecimentos?.EstabelecimentosFilhos)) {
        estabelecimentos?.EstabelecimentosFilhos.forEach((estabelecimento) => {
          array = [...array, ...getAllChild(estabelecimento)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(estabelecimentos: IEstablishmentTree, id: number) {
      if (estabelecimentos?.IdEstabelecimento === id) {
        return estabelecimentos;
      } else if (Array.isArray(estabelecimentos?.EstabelecimentosFilhos)) {
        let result = null;
        estabelecimentos?.EstabelecimentosFilhos.forEach((estabelecimento) => {
          if (!!getNodeById(estabelecimento, id)) {
            result = getNodeById(estabelecimento, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(estabelecimentos, id));
  }

  function getOnChange(checked: boolean, estabelecimentos: IEstablishmentTree) {
    const allNode: number[] = getChildById(
      estabelecimentos,
      estabelecimentos?.IdEstabelecimento,
    );
    let array = checked
      ? [...estabsFromUserChoice, ...allNode]
      : estabsFromUserChoice.filter((value) => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setEstabsFromUserChoice(array);
    onChangeEstabs(array);
  }

  useEffect(() => {
    setEstabsFromDatabase(establishments);
    setEstabsFromUserChoice(selectedEstablishments);

    const allNodes: number[] = getChildById(
      establishments[0],
      establishments[0]?.IdEstabelecimento,
    );
    setExpanded(allNodes.map((node) => node.toString()));

    // eslint-disable-next-line
  }, [establishments, selectedEstablishments]);

  const renderizarEstabelecimentos = (estabelecimentos: IEstablishmentTree) => (
    <TreeItem
      key={estabelecimentos?.IdEstabelecimento}
      nodeId={`${estabelecimentos?.CodigoEstruturadoInt}`}
      label={
        <Box>
          <Checkbox
            edge="start"
            checked={estabsFromUserChoice.includes(
              estabelecimentos?.IdEstabelecimento,
            )}
            onChange={(event) => {
              getOnChange(event.currentTarget.checked, estabelecimentos);
            }}
            onClick={(event) => event.stopPropagation()}
          />

          {`${estabelecimentos?.CodigoEstruturadoInt} - ${estabelecimentos?.NomeEstabelecimento} `}
        </Box>
      }>
      {Array.isArray(estabelecimentos?.EstabelecimentosFilhos)
        ? estabelecimentos?.EstabelecimentosFilhos.map((estabelecimento) =>
            renderizarEstabelecimentos(estabelecimento),
          )
        : null}
    </TreeItem>
  );

  return (
    <Box>
      <TreeView
        expanded={expanded}
        selected={selected}
        handleToggle={handleToggle}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<ExpandMore style={{marginRight: 2}} />}
        defaultExpandIcon={<ChevronRight />}>
        {estabsFromDatabase?.length > 0 ? (
          estabsFromDatabase?.map((estab) => renderizarEstabelecimentos(estab))
        ) : (
          <Box padding={2}>
            <Typography color="secondary">
              Nenhum estabelecimento encontrado...
            </Typography>
          </Box>
        )}
      </TreeView>
    </Box>
  );
};
