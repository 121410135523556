import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {
  PublicoAlvoList,
  GrupoPapeisList,
  GrupoPapeisDetail,
  PublicoAlvoDetail,
  Usuarios,
} from 'pages';
import {AdicionarPermissoes} from 'pages/usuarios/permissoes/AdicionarPermissoes';
import { Recorrencia } from 'pages/agendamento/Agendamento';
import { RecorrenciaDetalhe } from 'pages/agendamento/AgendamentoDetalhe';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/publico-alvo" element={<PublicoAlvoList />} />
      <Route path="/publico-alvo/detail/:id" element={<PublicoAlvoDetail />} />

      <Route path="/grupo-de-papeis" element={<GrupoPapeisList />} />
      <Route
        path="/grupo-de-papeis/detail/:id"
        element={<GrupoPapeisDetail />}
      />

      <Route path="/usuarios" element={<Usuarios />} />

      <Route
        path="/adicionar-permissoes/:id/:name"
        element={<AdicionarPermissoes />}
      />

      <Route path="/recorrencia" element={<Recorrencia />} />
      <Route
        path="/recorrencia/:idRecorrencia"
        element={<RecorrenciaDetalhe />}
      />

      <Route path="*" element={<Navigate to="/publico-alvo" />} />
    </Routes>
  );
};
