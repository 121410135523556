import {Environment} from 'shared/Env';
import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IUser {
  name: string;
  forgotPasswordLink: string;
}

const checkSession = async (): Promise<IResultOfAction<any>> => {
  try {
    const {headers} = await Api.get('/PublicosAlvo/Resumido');

    const response: IResultOfAction<any> = {
      Success: true,
      Message: '',
      Data: headers,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const signout = async (): Promise<boolean> => {
  try {
    if (Environment.URL_ORIGIN_DEBUG.length > 0) {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}&origin_debug=${Environment.URL_ORIGIN_DEBUG}`;
    } else {
      window.location.href = `${Environment.API_GATEWAY}/SignOut?callback_url=${window.location.href}`;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const UserService = {
  signout,
  checkSession,
};
