import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/interceptors';

export interface IPeriodos {
  Id: string;
  DescricaoPeriodo: string;
  Selecionado: boolean;
}

export interface IPeriodosAcademicos {
  AnosLetivos?: string[];
  PeriodosAcademicos: IPeriodos[];
}

export interface IPeriodosPost {
  IdPublicoAlvo: string;
  Filtros: [
    {Chave: 'periodos'; IdsValoresSelecionados: string[]},
    {Chave: 'anosletivos'; IdsValoresSelecionados: string[]},
  ];
}

const getById = async (
  idPublicoAlvo: string,
  anoLetivo?: string[],
): Promise<IResultOfAction<IPeriodosAcademicos>> => {
  try {
    const {data} = await Api.get(
      `/Periodos/${idPublicoAlvo}${
        anoLetivo !== undefined ? '?anosLetivos=' + anoLetivo?.join(',') : ''
      }`,
    );

    const response: IResultOfAction<IPeriodosAcademicos> = {
      Success: true,
      Message: '',
      Data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const post = async (
  periodos: IPeriodosPost,
): Promise<IResultOfAction<IPeriodosPost>> => {
  try {
    const {data} = await Api.post(`Periodos`, periodos);

    return data;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const PeriodosService = {
  getById,
  post,
};
