import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  TableRow,
  Container,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  FormControl,
  InputAdornment,
  TableContainer,
  Chip,
  Tooltip,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Divider,
  TextField,
} from '@mui/material';
import {Pagination, Skeleton} from '@mui/lab';
import Add from '@mui/icons-material/Add';
import Search from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import WarningIcon from '@mui/icons-material/Warning';
import {useTitle, useDebounce, useNotification} from 'shared/hooks';
import {confirm, feedback} from 'shared/services';
import {ActionButtons, NotFoundText} from 'shared/components';
import {
  PublicoAlvoListService,
  IPublicoAlvo,
} from 'shared/services/api/publico-alvo/PublicoAlvoList';
import {Environment} from 'shared/Env';
import {useNavigate} from 'react-router-dom';
import {History} from './components/History';
import {PublicoAlvoDetailsService} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';

export const PublicoAlvoList: React.FC = () => {
  const {notificacoes} = useNotification();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {debounce} = useDebounce();
  const [openHistory, setOpenHistory] = useState(false);
  const [currentIdOnHistory, setCurrentIdOnHistory] = useState('');
  const [currentNameOnHistory, setCurrentNameOnHistory] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [publicoAlvo, setPublicoAlvo] = useState<IPublicoAlvo[]>([]);
  const [publicoAlvoToSearch, setPublicoAlvoToSearch] = useState<
    IPublicoAlvo[]
  >([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(Environment.LIMITE_DE_LINHAS);

  const publicoCopy = useMemo(() => {
    return publicoAlvo;
  }, [publicoAlvo]);

  const notificacacoesCopy = useMemo(() => {
    return notificacoes;
  }, [notificacoes]);

  useEffect(() => {
    setTitle('Público-alvo');
  }, [setTitle]);

  useEffect(() => {
    PublicoAlvoListService.getAll().then((data) => {
      if (data.Success) {
        setPublicoAlvo(data.Data);
        setPublicoAlvoToSearch(data.Data);
      } else {
        feedback(
          data.Message || 'Erro ao listar os públicos-alvo disponíveis.',
          'error',
        );
      }
      setIsLoading(false);
    });
  }, []);

  const removeAccentuation = useCallback((value: string) => {
    const resultFilter = value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return resultFilter;
  }, []);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      setSearch(e.target.value);

      debounce(() => {
        const valor = removeAccentuation(e.target.value);

        const result = publicoAlvoToSearch.filter(
          (item) => removeAccentuation(item.Nome).indexOf(valor) > -1,
        );

        if (e.target.value === '') {
          setPublicoAlvo(result);
        } else {
          setPublicoAlvo(result);
        }
        setPage(1);
        setIsLoading(false);
      });
    },
    [debounce, removeAccentuation, publicoAlvoToSearch],
  );

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleDelete = useCallback((id: string) => {
    const handleConfirmDelete = () => {
      PublicoAlvoListService.deleteById(id).then((data) => {
        if (data.Success) {
          setPublicoAlvo((oldRows) => oldRows?.filter((row) => row.Id !== id));
          setPublicoAlvoToSearch((oldRows) =>
            oldRows?.filter((row) => row.Id !== id),
          );
          feedback('Público-alvo excluído com sucesso.', 'success');
        } else {
          feedback(data.Message || 'Erro ao excluir público-alvo.', 'error');
        }
      });
    };

    confirm(
      'Tem certeza que deseja excluir este registro?',
      'delete',
      handleConfirmDelete,
    );
  }, []);

  const handleStartBackgroundProcess = useCallback((idPublicoAlvo: string) => {
    setIsLoading(true);
    PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then((result) => {
      if (!result.Success) {
        feedback(
          result.Message || 'Falha ao reinicar processamento do público-alvo.',
          'error',
        );
      }
      setIsLoading(false);
    });
  }, []);

  const formataStatus = useCallback(
    (status: string, idPublicoAlvo: string) => {
      switch (status) {
        case 'AGUARDANDO_PROCESSAMENTO':
          return (
            <>
              <Tooltip title="Público-alvo na fila para processar pessoas. Não será possível editar até que o processo acabe.">
                <IconButton size="small" onClick={() => {}}>
                  <CircularProgress
                    size={16}
                    style={{
                      color: '#018781',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          );
        case 'EM_PROCESSAMENTO':
          return (
            <>
              <Tooltip title="Público-alvo processando pessoas. Não será possível editar até que o processo acabe.">
                <IconButton size="small" onClick={() => {}}>
                  <CircularProgress
                    size={16}
                    style={{
                      color: '#018781',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </>
          );
        case 'ERRO_PROCESSAMENTO':
          return (
            <>
              <Tooltip title="Um erro ocorreu durante o processamento. Clique para começar o processo novamente.">
                <IconButton
                  size="small"
                  onClick={() => {
                    handleStartBackgroundProcess(idPublicoAlvo);
                  }}>
                  <WarningIcon color={'error'} />
                </IconButton>
              </Tooltip>
            </>
          );
        case 'CONCLUIDO':
          return (
            <>
              <Tooltip title="Acessar pessoas do público-alvo">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(
                      `/publico-alvo/detail/${idPublicoAlvo}?lastStep=true`,
                    );
                  }}>
                  <PeopleIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        default:
          return;
      }
    },
    [navigate, handleStartBackgroundProcess],
  );

  useEffect(() => {
    setTimeout(() => {
      const publicosToChangeStatus = publicoCopy.filter((publico) => {
        return notificacacoesCopy.some((noti) => {
          return (
            publico.Status !== 'CONCLUIDO' && noti.IdPublicoAlvo === publico.Id
          );
        });
      });

      if (publicosToChangeStatus.length > 0) {
        setIsLoading(true);
        setPublicoAlvo((oldPublico) =>
          oldPublico.map((publico) =>
            publicosToChangeStatus.some(
              (updatedPublico) => publico.Id === updatedPublico.Id,
            )
              ? {...publico, Status: 'CONCLUIDO'}
              : publico,
          ),
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      }
    }, 60000);
  }, [notificacacoesCopy, publicoCopy]);

  const handleCloseHistory = useCallback(() => {
    setOpenHistory(false);
  }, []);

  const handleOpenHistory = useCallback(
    (idPublicoAlvo: string, nomePublico: string) => {
      setCurrentIdOnHistory(idPublicoAlvo);
      setCurrentNameOnHistory(nomePublico);
      setOpenHistory(true);
    },
    [],
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={0}>
          <Grid container item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Paper>
                <TextField
                  style={{padding: 0, margin: 0}}
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar por nome do público-alvo"
                  value={search}
                  onChange={handleSearch}
                  InputProps={{
                    style: {
                      height: 40,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={6}
            container
            justifyContent={'flex-end'}
            alignItems={'center'}>
            <Grid item xs={3}>
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => navigate('/publico-alvo/detail/new')}>
                Novo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={400} component="th" scope="row">
                    Nome público-alvo
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Estabelecimento
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width={200}
                    align={'right'}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width="auto"
                          height={25}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : publicoAlvo.length ? (
                  publicoAlvo
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage,
                    )
                    .map((row) => (
                      <TableRow hover key={row.Id}>
                        <TableCell component="th" scope="row">
                          {row.Nome}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.Estabelecimentos[0]?.NomeEstabelecimento}
                          {row.Estabelecimentos.length - 1 > 0 ? (
                            <Tooltip
                              title={row.Estabelecimentos.slice(
                                1,
                                row.Estabelecimentos.length,
                              )
                                .map((estab) => estab.NomeEstabelecimento)
                                .join(', ')}>
                              <Chip
                                style={{marginLeft: '5px'}}
                                size="small"
                                color={'secondary'}
                                label={`+ ${row.Estabelecimentos.length - 1}`}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          width={200}
                          align={'right'}>
                          <Tooltip title={'Acessar histórico do público-alvo.'}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleOpenHistory(row.Id, row.Nome);
                              }}>
                              <HistoryIcon />
                            </IconButton>
                          </Tooltip>
                          {formataStatus(row.Status as string, row.Id)}
                          <ActionButtons
                            rowId={row.Id}
                            canEdit={
                              (row.Status === 'CONCLUIDO' ||
                                row.Status === 'EM_ELABORACAO' ||
                                row.Status === 'ERRO_PROCESSAMENTO' ||
                                row.Status === null) &&
                              row.PermiteEdicao
                                ? true
                                : false
                            }
                            canDelete={
                              row.Status === 'CONCLUIDO' ||
                              row.Status === 'EM_ELABORACAO' ||
                              row.Status === 'ERRO_PROCESSAMENTO' ||
                              row.Status === null
                                ? true
                                : false
                            }
                            onClickEdit={() =>
                              navigate(`/publico-alvo/detail/${row.Id}`)
                            }
                            onClickDelete={() => handleDelete(row.Id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <NotFoundText />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {publicoAlvo.length > rowsPerPage && (
              <Box
                marginTop={1}
                marginBottom={1}
                marginLeft={1}
                marginRight={1}
                display={'flex'}
                justifyItems={'end'}>
                <Pagination
                  page={page}
                  count={Math.ceil(publicoAlvo.length / rowsPerPage)}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={openHistory} onClose={handleCloseHistory} maxWidth={'xl'}>
        <DialogTitle id="modal-detail-title">
          <Box
            display={'flex'}
            justifyItems={'space-between'}
            alignItems={'center'}>
            <Box width={'100%'}>
              <Typography color="primary" variant="h6">
                Histórico de processamento do público-alvo
              </Typography>
            </Box>
            <IconButton aria-label="close" onClick={handleCloseHistory}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <History
            idPublicoAlvo={currentIdOnHistory}
            nomePublico={currentNameOnHistory}
            setIsLoadingList={(thisIsLoading) => {
              setIsLoading(thisIsLoading);
            }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};
